import {put, takeLatest, call, select, all} from 'redux-saga/effects';
import * as Selectors from 'modules/projects/selectors';
import {Actions} from 'modules/projects/actions';
import axios from 'services/axios';
import {ActionTypes as PagesActions} from 'pages/actions';
import fileSaver from 'file-saver';
import _ from 'lodash';
import { s2ab } from 'utils/transformers';

function* fetchProjects(sendRequest) {
    if (sendRequest) {
        yield put(Actions.FETCH_PROJECTS_REQUEST());
    }
    try {
        const {showArchived} = yield select(Selectors.filter);
        const query = `active=${!showArchived}`;
        const {data} = yield call(axios.get, `projects?${query}`);
        
        yield put(Actions.FETCH_PROJECTS_SUCCESS(data));
    } catch (err) {
        console.error(err);
        yield put(Actions.FETCH_PROJECTS_FAILURE(err));
    }
}

function* fetchProjectWithPosts(action) {
    yield put(Actions.FETCH_S_PROJECT_REQUEST());
    try {
        const projectId = yield select(Selectors.matchingProjectId);
        const {project, posts} = yield all({
            project: call(axios.get, `projects/${projectId}`),
            posts: call(axios.get, `projects/${projectId}/posts`),
        })

        yield put(Actions.FETCH_S_PROJECT_SUCCESS({...project.data, posts: posts.data.items}));
    } catch (err) {
        yield put(Actions.FETCH_S_PROJECT_FAILURE(err));
    }
}

function* fetchProjectWithActivities(action) {
    yield put(Actions.FETCH_S_ACTIVITIES_REQUEST());
    try {
        const projectId = yield select(Selectors.matchingProjectId);
        const supplierId = yield select(Selectors.matchingSupplierId);
        const {project, supplier} = yield all({
            project: call(axios.get, `projects/${projectId}`),
            supplier: call(axios.get, `projects/${projectId}/suppliers/${supplierId}`),
        })

        yield put(Actions.FETCH_S_ACTIVITIES_SUCCESS({...project.data, supplier: supplier.data}));
    } catch (err) {
        yield put(Actions.FETCH_S_ACTIVITIES_FAILURE(err));
    }
}

function* submitProjectForm(action) {
    yield put(Actions.PROJECT_FORM_SUBMIT_REQUEST());
    try {
        const {payload} = action;
        const projectId = payload.id;
        const fields = _.pick(payload, ['name', 'baseUrl', 'apiKey']);
        const method = projectId ? axios.patch : axios.post;
        const url = projectId ? `projects/${projectId}` : `projects`;
        const result = yield call(method, url, fields);

        yield put(Actions.PROJECT_FORM_SUBMIT_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.PROJECT_FORM_SUBMIT_FAILURE(err));
    }
}

function* deleteProject(action) {
    yield put(Actions.PROJECT_DELETE_REQUEST());
    try {
        const {payload} = action;
        const result = yield call(axios.delete, `projects/${payload.id}`);

        yield put(Actions.PROJECT_DELETE_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.PROJECT_DELETE_FAILURE(err));
    }
}

function* submitCreateEditPostForm(action) {
    yield put(Actions.POST_EDIT_REQUEST());
    try {
        const {values, shouldPublish} = action.payload;
        const postId = values.id || '';
        const projectId = yield select(Selectors.matchingProjectId);
        const fields = _.pick(values, ['title', 'slug', 'description', 'seoTitle', 'seoDescription', 'seoFocusKeyword', 'categories', 'featureImage']);
        const method = postId ? axios.patch : axios.post;
        const url = `projects/${projectId}/posts/${postId}`;
        const result = yield call(method, url, fields);
        let newPost = result.data;
        if (shouldPublish) {
            const publishResult = yield call(axios.post, `projects/${projectId}/posts/${result.data.id}/publish`);
            newPost = publishResult.data;
        }
        yield put(Actions.POST_EDIT_SUCCESS(newPost));
    } catch (err) {
        console.error(err);
        yield put(Actions.POST_EDIT_FAILURE(err));
    }
}

function* changeProjectSupplierState(action) {
    yield put(Actions.PROJECT_SUPPLIER_CHANGE_STATE_REQUEST());
    try {
        const {payload} = action;
        const projectId = yield select(Selectors.matchingProjectId);
        const result = yield call(axios.patch, `projects/${projectId}/suppliers/${payload.id}`, {active: !payload.active});

        yield put(Actions.PROJECT_SUPPLIER_CHANGE_STATE_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.PROJECT_SUPPLIER_CHANGE_STATE_FAILURE(err));
    }
}

function* downloadProjectReport() {
    try {
        const project = yield select(Selectors.selectedProject);
        
        yield put(Actions.PROJECT_GET_REPORT_REQUEST());
        const result = yield call(axios.post, `projects/${project.id}/prepareReport`);
        const currentDate = new Date().toJSON().slice(0,10).split('-').reverse().join('.');
        fileSaver.saveAs(new Blob([s2ab(atob(result.data))], {type: result.headers['content-type']}), `${project.name}-מסך פרויקט-${currentDate}.xlsx`);
        yield put(Actions.PROJECT_GET_REPORT_SUCCESS());
    } catch (err) {
        yield put(Actions.PROJECT_GET_REPORT_FAILURE(err));
    }
}

function* publishSinglePost(action) {
    try {
        const project = yield select(Selectors.selectedProject);
        const post = action.payload;

        yield put(Actions.POST_PUBLISH_REQUEST());
        yield call(axios.post, `projects/${project.id}/posts/${post.id}/publish`);
        yield put(Actions.POST_PUBLISH_SUCCESS());
    } catch (err) {
        yield put(Actions.POST_PUBLISH_FAILURE(err));
    }
}

function* saveAndPublishPost(action) {
    try {
        const project = yield select(Selectors.selectedProject);
        const post = action.payload;

        yield put(Actions.POST_PUBLISH_REQUEST());
        yield call(axios.post, `projects/${project.id}/posts/${post.id}/publish`);
        yield put(Actions.POST_PUBLISH_SUCCESS());
    } catch (err) {
        yield put(Actions.POST_PUBLISH_FAILURE(err));
    }
}

const sagas = [
    takeLatest([
        PagesActions.SHARES_PAGE_MOUNTED,
        PagesActions.PROJECTS_PAGE_MOUNTED,
    ], fetchProjects, true),
    takeLatest([
        PagesActions.HEADER_SEARCH_PROJECT_FOCUSED,
    ], fetchProjects, false),
    takeLatest([PagesActions.S_PROJECT_PAGE_MOUNTED], fetchProjectWithPosts),
    takeLatest([PagesActions.PROJECT_SUP_PAGE_MOUNTED], fetchProjectWithActivities),
    takeLatest(PagesActions.PROJECT_FORM_SUBMITTED, submitProjectForm),
    takeLatest(PagesActions.CREATE_EDIT_POST_FORM_SUBMITTED, submitCreateEditPostForm),
    takeLatest(PagesActions.PROJECTS_PAGE_DELETE_PROJECT, deleteProject),
    takeLatest(PagesActions.S_PROJECT_PAGE_TOGGLE_SUPPLIER_STATE, changeProjectSupplierState),
    takeLatest(PagesActions.S_PROJECT_PAGE_DOWNLOAD_REPORT, downloadProjectReport),
    takeLatest(PagesActions.PUBLISH_SINGLE_POST_ACTION, publishSinglePost),
    takeLatest(PagesActions.SAVE_AND_PUBLISH_POST_ACTION, saveAndPublishPost),
]
export default sagas;
