import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowForward as ArrowForwardIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

// styles
import useStyles from "./styles";

// components
import { Typography } from "components/Wrappers/Wrappers";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

export default function Header({projects, onSearchProject, user, onLogout, history}) {
  var classes = useStyles();
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  var [profileMenu, setProfileMenu] = useState(null);
  const [open, setOpen] = React.useState(false);
  const loading = open && !projects.length;

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    onSearchProject()

  }, [loading, open, onSearchProject]);

  const options = projects.map(p => ({id: p.id, name: p.name}))
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowForwardIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          Push the Content
        </Typography>
        <div className={classes.grow}></div>
        <Autocomplete
          id="asynchronous-demo"
          style={{ width: 300 }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option, value) => option.name}
          options={options}
          loading={loading}
          value={null}
          onChange={(event, newValue) => {
            history.push(`/app/projects/${newValue.id}`);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="חפש פרויקט"
              variant="filled"
              size="small"
              classes={{root: classes.asyncTextField, }}
              InputProps={{
                ...params.InputProps,
                classes: {root: classes.asyncTextField},
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {user.displayName}
            </Typography>
          </div>

          <MenuItem
            onClick={onLogout}
            className={classNames(
              classes.headerMenuItem,
              classes.logoutMenuItem,
            )}
          >
            <ExitToAppIcon className={classes.profileMenuIcon} /> התנתקות
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
