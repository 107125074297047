import React from "react";
import MUIDataTable from "mui-datatables";
import {Button, Grid, FormControlLabel, Switch, Tooltip, IconButton} from '@material-ui/core';
import {
  Edit as EditIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
} from '@material-ui/icons';

// components
import PageTitle from "../../components/PageTitle";
import {muiDataTableLabels} from 'utils/i18nUtils'
import Loader from "components/Loader/Loader";
import CreateEditSupplier from "components/CreateEditSupplier";
import { withStyles } from "@material-ui/styles";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { centralizeColumn } from "utils/materialUtils";
import SupplierProjectsModal from "components/SupplierProjectsModal";


class SuppliersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierDialogOpen: false,
      supplierModalDialogOpen: false,
      confirmation: {open: false},
      columns: [
        {name: 'name', label: 'שם המאמר'},
        {name: 'phone', label: 'טלפון'},
        {name: 'email', label: 'אימייל'},
        {name: 'location', label: 'מיקום'},
        {name: 'bank', label: 'פרטי חשבון', options: {setCellProps: () => ({classes: {root: props.classes.bankCell}})}},
        {
          name: 'actions',
          label: 'פעולות',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: this.renderActionsColumn,
            ...centralizeColumn,
          },
        },
      ]
    }
  }
  componentDidMount = () => {
    this.props.onMount();
  }
  componentDidUpdate = (oldProps) => {
    if (!oldProps.justSubmitted && this.props.justSubmitted) {
      this.setState({justSubmitted: true})
    }
  }

  handleShowArchivedChange = (e) => {
    const isHidden = e.target.checked;
    this.props.onShowHiddenChange(isHidden);
  }

  handleCellClick = (_, {colIndex, dataIndex}) => {
    if (colIndex === this.state.columns.length -1) {
      return;
    }
    const {suppliers} = this.props;
    const supplier = suppliers[dataIndex];
    this.setState({selectedSupplier: supplier, supplierModalDialogOpen: true})
  }

  handleEditIcon = (supplier) => {
    this.setState({selectedSupplier: supplier, supplierDialogOpen: true, justSubmitted: false});
  }
  
  handleSupplierDialogClose = () => {
    this.setState({supplierDialogOpen: false, justSubmitted: false});
  }

  closeConfirmationDialog = () => {
    this.setState({confirmation: {open: false}});
  }

  handleSupplierChangeState = (supplier) => {
    this.closeConfirmationDialog();
    this.props.onSupplierChangeState(supplier)
  }

  confirmChangeSupplierState = (supplier) => {
    const activateTexts = {
      title: `הוצאת מאמר מארכיון - ${supplier.name}`,
      subtitle: 'האם אתה בטוח כי ברצונך להוציא מאמר זה מארכיון?',
      confirmText: 'הוצאה מארכיון',
    }
    const archiveTexts = {
      title: `העברת מאמר לארכיון - ${supplier.name}`,
      subtitle: 'האם אתה בטוח כי ברצונך להעביר מאמר זה לארכיון?',
      confirmText: 'העברה לארכיון',
    }
    const confirmation = {
      open: true,
      ...(supplier.active ? archiveTexts : activateTexts),
      onConfirm: () => this.handleSupplierChangeState(supplier),
      onCancel: this.closeConfirmationDialog
    }
    this.setState({confirmation})
  }

  renderToolbar = () => {
    const {showArchived} = this.props.filter || {};

    return (
      <>
        <FormControlLabel
          control={<Switch checked={showArchived} onChange={this.handleShowArchivedChange} name="checkedA" />}
          label="הצג מאמרים מוסתרים"
        />
        <Button variant="contained" color="primary" onClick={() => this.handleEditIcon(undefined)}>מאמר חדש</Button>
      </>
    );
  }

  renderActionsColumn = (dataIndex) => {
    const {suppliers, classes} = this.props;
    const supplier = suppliers[dataIndex]
    if (!supplier) {return;}

    const Icon = supplier.active ? ArchiveIcon : UnarchiveIcon;
    const text = supplier.active ? 'העבר לארכיון' : 'הפוך לפעיל';
    return (
      <>
        <Tooltip arrow title={text}>
          <IconButton aria-label={text} className={classes.actionIcon} onClick={() => this.confirmChangeSupplierState(supplier)} >
            <Icon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="עריכת מאמר">
          <IconButton aria-label="עריכת מאמר" className={classes.actionIcon} onClick={() => this.handleEditIcon(supplier)}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderConfirmDialog = () => {
    return (
      <ConfirmDialog
          {...this.state.confirmation}
      />
    );
  }
  render() {
    const {suppliers, loading} = this.props;
    const {supplierDialogOpen, selectedSupplier, columns, justSubmitted, supplierModalDialogOpen} = this.state;
    return (
      <>
        <PageTitle title="המאמרים שלי" />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Loader loading={loading}>
              <MUIDataTable
                title="רשימת מאמרים"
                data={suppliers}
                columns={columns}
                options={{
                  sortOrder: {name: 'name', direction: 'asc'},
                  filterType: "textField",
                  onCellClick: this.handleCellClick,
                  textLabels: muiDataTableLabels,
                  selectableRows: 'none',
                  filter: false,
                  search: false,
                  searchOpen: true,
                  print: false,
                  download: false,
                  viewColumns: false,
                  selectableRowsOnClick: true,
                  responsive: 'standard',
                  customToolbar: this.renderToolbar,
                  tableBodyMaxHeight: '700px',
                }}
              />
            </Loader>
          </Grid>
        </Grid>
        <CreateEditSupplier
          justSubmitted={justSubmitted}
          open={supplierDialogOpen}
          supplier={selectedSupplier}
          onClose={this.handleSupplierDialogClose}
        />
        <SupplierProjectsModal
          supplier={selectedSupplier}
          open={supplierModalDialogOpen}
          onClose={() => this.setState({supplierModalDialogOpen: false})}
        />
        {this.renderConfirmDialog()}
      </>
    );
  }
}

const styles = theme => ({
  actionIcon: {
    padding: theme.spacing(1),
  },
  summaryTable: {
    marginTop: theme.spacing(4),
  },
  bankCell: {
    maxWidth: 350,
  },
})
export default withStyles(styles, { withTheme: true })(SuppliersPage);