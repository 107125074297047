import React, {useEffect, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, CircularProgress, DialogTitle,
  TextField, TableContainer, Toolbar, Typography,
  Table, TableBody, TableRow, TableCell, TableHead,
} from '@material-ui/core';
import Yup from 'services/yup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import classnames from 'classnames';

const numField = Yup.number().required('זהו שדה חובה');
const rowsValidations = Yup.array().of(
  Yup.object().shape({
    name: Yup.string().required('זהו שדה חובה'),
    quantity: numField,
    price: numField,
}));

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  },
  cellError: {
    background: theme.palette.error.light,
  }
}));

const valueConverter = (value) => {
  const rows = value.split('\n').filter(_.identity);
  const result = rows.map(rowStr => {
    const [name, quantity, rawPrice] = rowStr.split('\t');
    const price = (rawPrice || '').replace(/,/g, '')
    return {name, quantity, price};
  });
  return result;
}

function getValidationErrors(rows) {
  try {
    rowsValidations.validateSync(rows, {abortEarly: false});
    return null;
  } catch (err) {
    return err.inner.reduce((aggr, err) => {
      const {path} = err;
      const [rawIndex, prop] = path.split('.');
      const index = rawIndex.slice(1, -1);
      aggr[index] = aggr[index] || {}
      aggr[index][prop] = err;
      return aggr;
    }, {});
  }
}
function ExcelPreview({value}) {
  const classes = useStyles();
  const rows = valueConverter(value);
  if (!rows.length) {
    return null;
  }
  
  const validationMap = getValidationErrors(rows);
  return (
    <TableContainer>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" component="div">תצוגה מקדימה</Typography>
      </Toolbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>פירוט הביצוע</TableCell>
            <TableCell>כמות</TableCell>
            <TableCell>מחיר יחידה</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index} hover className={classes.tableRow}>
              <TableCell className={classnames(classes.tableCell, {[classes.cellError]: _.get(validationMap, `${index}.name`)})}>{row.name}</TableCell>
              <TableCell className={classnames(classes.tableCell, {[classes.cellError]: _.get(validationMap, `${index}.quantity`)})}>{row.quantity}</TableCell>
              <TableCell className={classnames(classes.tableCell, {[classes.cellError]: _.get(validationMap, `${index}.price`)})}>{row.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default function BulkAddActivities({open, onClose, onSubmit, loading, justSubmitted}) {
  const [fieldValue, setFieldValue] = useState('');
  useEffect(() => {
    if (open && justSubmitted) {
      onClose();
    }
  }, [justSubmitted, onClose, open])


  const classes = useStyles();

  function handleSubmit(e) {
    e.preventDefault();
    const rows = valueConverter(fieldValue);
    if (rows.length && !getValidationErrors(rows)) {
      onSubmit(rows);
    }
  }
  return (
  <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="submit-activity-title" disableBackdropClick disableEscapeKeyDown={loading}>
    <DialogTitle classes={{root: classes.dialogTitle}} id="submit-activity-title">יצירת ביצועים מאקסל</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>
        יש להעתיק את השדות מהאקסל לפי הסדר הבא: 
        <Typography variant="h6" component="div">פירוט הביצוע, כמות, מחיר יחידה</Typography>
      </DialogContentText>
      {open && (
            <form onSubmit={handleSubmit}>
              <TextField
                name="value"
                multiline
                rowsMax={3}
                fullWidth
                variant="outlined"
                label="יבוא מאקסל"
                value={fieldValue}
                onChange={e => setFieldValue(e.target.value)}
              />
              <ExcelPreview value={fieldValue} />
              <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                  ביטול
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    יצירה
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </DialogActions>
            </form>
          )
      }
    </DialogContent>
  </Dialog>
  );
}