import { mergeBy } from 'utils/reduxUtils';
import {ActionTypes} from './actions';
import {ActionTypes as PagesActionTypes} from 'pages/actions';

const initialValue = {
    filter: {showArchived: false},
}

const suppliersReducer = (state = initialValue, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.LOAD_SUPPLIERS_REQUEST:
        nextState = {...state, suppliers: [], loading: true};
        break;
    case ActionTypes.LOAD_SUPPLIERS_SUCCESS:
        nextState = {...state, suppliers: payload.items, loading: false};
        break;
    case ActionTypes.LOAD_SUPPLIERS_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.SUPPLIER_FORM_SUBMIT_REQUEST:
        nextState = {...state, submitting: true, justSubmitted: false, submitError: null};
        break;
    case ActionTypes.SUPPLIER_FORM_SUBMIT_SUCCESS:
        nextState = {...state, submitting: false, justSubmitted: true, suppliers: mergeBy(state.suppliers, payload)};
        break;
    case ActionTypes.SUPPLIER_FORM_SUBMIT_FAILURE:
        nextState = {...state, submitting: false, submitError: 'אופס. נראה שהבקשה נכשלה'};
        break;
    case ActionTypes.LOAD_SUPPLIER_PROJECTS_SUCCESS:
        nextState = {...state, supplierProjects: payload.items, submitting: false};
        break;
    case ActionTypes.LOAD_SUPPLIER_PROJECTS_REQUEST:
        nextState = {...state, supplierProjects: null, submitting: true};
        break;
    case ActionTypes.LOAD_SUPPLIER_PROJECTS_FAILURE:
        nextState = {...state, submitting: false};
        break;
    case ActionTypes.SUPPLIER_CHANGE_STATE_SUCCESS:
        nextState = {...state, suppliers: state.suppliers.filter(p => p.id !== payload.id)};
        break;
    case ActionTypes.SUPPLIER_SUPPLIER_CHANGE_STATE_SUCCESS: {
        const {suppliers} = state.selectedProject;
        const selectedProject = {...state.selectedProject, suppliers: suppliers.filter(p => p.id !== payload.id)}
        nextState = {...state, selectedProject};
        break;
    }
    case PagesActionTypes.SUPPLIERS_PAGE_SHOW_ARCHIVED_CHANGE:
        nextState = {...state, filter: {showArchived: payload}};
        break;
    default:
        nextState = state;
    }
    return nextState;
};

export default suppliersReducer;
