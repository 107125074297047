import _ from 'lodash'
import React from 'react';
import { useField } from 'formik';
import MuiTextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    progressBackground: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    belowColor: {
        backgroundColor: theme.palette.warning.dark,
    },
    validColor: {
        backgroundColor: theme.palette.success.main,
    },
    aboveColor: {
        backgroundColor: theme.palette.error.main,
    },
    progressHelperText: {
        margin: 0,
    }
}));

export default function TextFieldProgress({fieldProps = {}, minLength, maxLength, ...props}) {
    const [field, meta] = useField(props);
    const currentLength = (field.value || '').length;
    const percent = _.clamp((currentLength / maxLength) * 100, 0, 100);
    const classes = useStyles();

    let color = classes.validColor;
    if (currentLength < minLength) {
        color = classes.belowColor
    } else if (currentLength > maxLength) {
        color = classes.aboveColor
    }
    return(
        <MuiTextField
            error={!!(meta.touched && meta.error)}
            margin="normal"
            fullWidth
            variant="outlined"
            FormHelperTextProps={{className: classes.progressHelperText}}
            id={props.name}
            {...field}
            {...fieldProps}
            helperText={<LinearProgress classes={{
                bar: color,
                colorPrimary: classes.progressBackground,
            }} variant="determinate" value={percent} />}
        />
    );
}