import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'FETCH_PROJECTS_REQUEST',
    'FETCH_PROJECTS_SUCCESS',
    'FETCH_PROJECTS_FAILURE',
    
    'ADD_PROJECT_REQUEST',
    'ADD_PROJECT_SUCCESS',
    'ADD_PROJECT_FAILURE',
    
    'TOGGLE_PROJECT_STATE_REQUEST',
    'TOGGLE_PROJECT_STATE_SUCCESS',
    'TOGGLE_PROJECT_STATE_FAILURE',
    
    'FETCH_S_PROJECT_REQUEST',
    'FETCH_S_PROJECT_SUCCESS',
    'FETCH_S_PROJECT_FAILURE',
    
    'FETCH_S_ACTIVITIES_REQUEST',
    'FETCH_S_ACTIVITIES_SUCCESS',
    'FETCH_S_ACTIVITIES_FAILURE',
    
    'PROJECT_FORM_SUBMIT_REQUEST',
    'PROJECT_FORM_SUBMIT_SUCCESS',
    'PROJECT_FORM_SUBMIT_FAILURE',
    
    'POST_EDIT_REQUEST',
    'POST_EDIT_SUCCESS',
    'POST_EDIT_FAILURE',
    
    'PROJECT_DELETE_REQUEST',
    'PROJECT_DELETE_SUCCESS',
    'PROJECT_DELETE_FAILURE',
    
    'PROJECT_SUPPLIER_CHANGE_STATE_REQUEST',
    'PROJECT_SUPPLIER_CHANGE_STATE_SUCCESS',
    'PROJECT_SUPPLIER_CHANGE_STATE_FAILURE',
    
    'PROJECT_GET_REPORT_REQUEST',
    'PROJECT_GET_REPORT_SUCCESS',
    'PROJECT_GET_REPORT_FAILURE',

    'POST_PUBLISH_REQUEST',
    'POST_PUBLISH_SUCCESS',
    'POST_PUBLISH_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
