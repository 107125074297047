import React from "react";
import MUIDataTable from "mui-datatables";
import {Button, Grid, FormControlLabel, Switch, Tooltip, IconButton} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';

// components
import PageTitle from "../../components/PageTitle";
import {muiDataTableLabels} from 'utils/i18nUtils'
import Loader from "components/Loader/Loader";
import CreateEditProject from "components/CreateEditProject";
import { withStyles } from "@material-ui/styles";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { centralizeColumn } from "utils/materialUtils";


class ProjectsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDialogOpen: false,
      confirmation: {open: false},
      columns: [
        {name: 'name', label: 'שם הפרויקט'},
        {name: 'baseUrl', label: 'דומיין'},
        {name: 'postCount', label: '# מאמרים'},
        {
          name: 'actions',
          label: 'פעולות',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: this.renderActionsColumn,
            ...centralizeColumn,
          },
        },
      ]
    }
  }

  componentDidUpdate = (oldProps) => {
    if (!oldProps.justSubmitted && this.props.justSubmitted) {
      this.setState({justSubmitted: true})
    }
  }
  componentDidMount = () => {
    this.props.onMount();
  }

  handleCellClick = (_, {colIndex, dataIndex}) => {
    if (colIndex === this.state.columns.length -1) {
      return;
    }
    const {projects, history, match} = this.props;
    const project = projects[dataIndex];
    if (!project) {
      return;
    }
    history.push(`${match.url}/${project.id}`);
  }

  handleEditIcon = (project) => {
    this.setState({selectedProject: project, projectDialogOpen: true, justSubmitted: false});
  }
  
  handleProjectDialogClose = () => {
    this.setState({projectDialogOpen: false, justSubmitted: false});
  }

  closeConfirmationDialog = () => {
    this.setState({confirmation: {open: false}});
  }

  handleProjectDelete = (project) => {
    this.closeConfirmationDialog();
    this.props.onProjectDeleted(project)
  }

  confirmDeleteProject = (project) => {
    const confirmation = {
      open: true,
      title: `מחיקת פרויקט`,
      subtitle: `האם למחוק את הפרויקט '${project.name}? פעולה זה לא ניתנת לביטול'`,
      confirmText: 'מחיקה',
      onConfirm: () => this.handleProjectDelete(project),
      onCancel: this.closeConfirmationDialog
    }
    this.setState({confirmation})
  }

  renderToolbar = () => {
    const {showArchived} = this.props.filter || {};

    return (
      <>
        <Button variant="contained" color="primary" onClick={() => this.handleEditIcon(undefined)}>פרויקט חדש</Button>
      </>
    );
  }

  renderActionsColumn = (dataIndex) => {
    const {projects, classes} = this.props;
    const project = projects[dataIndex]
    if (!project || project.role !== 'admin') {return;}
    
    return (
      <>
        <Tooltip arrow title="מחיקת פרויקט">
          <IconButton aria-label="מחיקת פרויקט" className={classes.actionIcon} onClick={() => this.confirmDeleteProject(project)} >
            <DeleteIcon fontSize="inherit" className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="עריכת פרויקט">
          <IconButton aria-label="עריכת פרויקט" className={classes.actionIcon} onClick={() => this.handleEditIcon(project)}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderConfirmDialog = () => {
    return (
      <ConfirmDialog
          {...this.state.confirmation}
      />
    );
  }
  render() {
    const {projects, loading} = this.props;
    const {projectDialogOpen, selectedProject, columns, justSubmitted} = this.state;
    return (
      <>
        <PageTitle title="הפרויקטים שלי" />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Loader loading={loading}>
              <MUIDataTable
                title="רשימת הפרויקטים"
                data={projects}
                columns={columns}
                options={{
                  filterType: "textField",
                  sortOrder: {name: 'name', direction: 'asc'},
                  onCellClick: this.handleCellClick,
                  textLabels: muiDataTableLabels,
                  selectableRows: 'none',
                  pagination: false,
                  filter: false,
                  search: false,
                  searchOpen: true,
                  print: false,
                  download: false,
                  viewColumns: false,
                  selectableRowsOnClick: true,
                  responsive: 'standard',
                  customToolbar: this.renderToolbar,
                }}
              />
            </Loader>
          </Grid>
        </Grid>
        <CreateEditProject
          justSubmitted={justSubmitted}
          open={projectDialogOpen}
          project={selectedProject}
          onClose={this.handleProjectDialogClose}
        />
        {this.renderConfirmDialog()}
      </>
    );
  }
}

const styles = theme => ({
  actionIcon: {
    padding: theme.spacing(1),
  },
  summaryTable: {
    marginTop: theme.spacing(4),
  },
  deleteIcon: {
    '&:hover': {
      fill: theme.palette.error.main,
    }
  }
})
export default withStyles(styles, { withTheme: true })(ProjectsPage);