import React, {useEffect, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogTitle, Grid, Box,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Formik} from 'formik';
import LoadingButton from 'components/LoadingButton';
import TextField from 'components/Form/TextField';
import TextFieldProgress from 'components/Form/TextFieldProgress';
import RichEditor from 'components/Form/RichEditor';
import AutoCompleteAsync from 'components/Form/AutoCompleteAsync';
import DropZone from 'components/Form/DropZone';
import Yup from 'services/yup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'

const fieldsValidations = {
  title: Yup.string().required('זהו שדה חובה'),
  featureImage: Yup.object().shape({
    content: Yup.string(),
  }),
  slug: Yup.string(),
  description: Yup.string().required('זהו שדה חובה'),
  seoTitle: Yup.string(),
  seoDescription: Yup.string(),
  seoFocusKeyword: Yup.string(),
  categories: Yup.array().of(Yup.string()),
};

const validationSchema = Yup.object().shape(fieldsValidations);
const fieldKeys = _.keys(fieldsValidations);
const emptyPost = _.transform(fieldKeys, (aggr, key) => aggr[key] = '', {});
emptyPost.featureImage = {}
emptyPost.categories = []

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  },
  buttonsWrapper: {
    gap: 10,
  }
}));

export default function CreateEditProjectPost({projectId, open, onClose, onSubmit, loading, justSubmitted, post, submitError, getTaxonomyTerms}) {
  post = post || _.clone(emptyPost)

  const [postVal, setProjectVal] = useState(_.clone(post || emptyPost));
  const [shouldPublish, setShouldPublish] = useState(false);
  useEffect(() => {
    if (open && justSubmitted) {
      onClose();
    }
  }, [justSubmitted, onClose, open])
  
  useEffect(() => {
    if (!_.isEqual(postVal, post)) {
      setProjectVal(_.clone(post || emptyPost))
    }
  }, [post, postVal])

  function fetchTaxonomies() {
    return getTaxonomyTerms(projectId);
  }
  const classes = useStyles();
  const texts = {
    title: postVal.id ? `שמירת מאמר - ${post.title}` : 'שמירת מאמר',
    action: postVal.id ? 'שמירה' : 'שמירה',
    publishAction: 'פרסום',
  }
  return (
  <Dialog
    fullWidth
    maxWidth="lg"
    open={open}
    onClose={onClose}
    aria-labelledby="submit-project-title"
    disableBackdropClick
    disableEscapeKeyDown={loading}
    >
    <DialogTitle classes={{root: classes.dialogTitle}} id="submit-project-title">{texts.title}</DialogTitle>
      {open && (<Formik
        initialValues={postVal}
        onSubmit={values => {
          onSubmit({values, shouldPublish})
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          return (
            <>
            <DialogContent dividers>
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <TextField
                      name="title"
                      fieldProps={{
                        label: 'כותרת המאמר',
                        InputProps: {readOnly: loading},
                      }}
                    />
                    <TextField
                      name="slug"
                      fieldProps={{
                        label: 'סלאג',
                        InputProps: {readOnly: loading},
                      }}
                    />
                    <RichEditor
                      name="description"
                      label="תאור"
                    />
                    <TextFieldProgress
                      name="seoTitle"
                      minLength={41}
                      maxLength={59}
                      fieldProps={{
                        multiline: true,
                        rows: 1,
                        rowsMax: 2,
                        label: 'כותרת YOAST',
                        InputProps: {readOnly: loading},
                      }}
                    />
                    <TextFieldProgress
                      name="seoDescription"
                      minLength={106}
                      maxLength={160}
                      fieldProps={{
                        multiline: true,
                        rows: 2,
                        rowsMax: 2,
                        label: 'תיאור YOAST',
                        InputProps: {readOnly: loading},
                      }}
                    />
                    <TextField
                      name="seoFocusKeyword"
                      fieldProps={{
                        label: 'ביטוי מפתח',
                        InputProps: {readOnly: loading},
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <DropZone
                      name="featureImage"
                      label="תמונה ראשית"
                      fieldProps={{
                        InputProps: {readOnly: loading},
                      }}
                    />
                    <AutoCompleteAsync
                      label="קטגוריות"
                      name="categories"
                      fieldProps={{
                        InputProps: {readOnly: loading},
                        classes: {root: classes.categories},
                      }}
                      fetchOptionsAsync={fetchTaxonomies}
                    />
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Box display="flex" flexDirection="row" flexGrow={1}>
                <Box flexGrow={1}>
                  {submitError && <Alert severity="error">{submitError}</Alert>}
                </Box>
                <Box display="flex" justify="center" className={classes.buttonsWrapper}>
                    <Button onClick={onClose} disabled={loading}>
                      ביטול
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="outlined"
                      color="primary"
                      disabled={loading}
                      loading={loading && !shouldPublish}
                      onClick={(e) => {
                        setShouldPublish(false)
                        props.handleSubmit(e)
                      }}
                    >
                      {texts.action}
                    </LoadingButton>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      loading={loading && shouldPublish}
                      onClick={(e) => {
                        setShouldPublish(true)
                        props.handleSubmit(e)}
                      }
                    >
                      {texts.publishAction}
                    </LoadingButton>
                </Box>
              </Box>
            </DialogActions>
          </>
          );
        }}
      </Formik>
      )}
  </Dialog>
  );
}