import React, {useEffect, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, CircularProgress, DialogTitle, Grid, TextField as MuiTextField, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel,
} from '@material-ui/core';
import {Formik, FieldArray} from 'formik';
import TextField from 'components/Form/TextField';
import Yup from 'services/yup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import { shallowCompare } from 'utils/generalUtils';

const yupPositiveField = Yup.number().min(0, 'חייב להיות גדול או שווה ל-0').required('זהו שדה חובה');
const yupPercentField = yupPositiveField.max(100, 'חייב להיות קטן או שווה ל-100')
const fieldsValidations = {
  activities: Yup.array().of(
    Yup.object().shape({
      discount: yupPercentField,
      completed: yupPercentField,
      lien: yupPercentField,
    })
  )
};

const validationSchema = Yup.object().shape(fieldsValidations);

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  }
}));

const updateKeys = [
  {name: 'discount', label: '(%) הנחה'},
  {name: 'completed', label: '(%) ביצוע'},
  {name: 'lien', label: '(%) עיכבון'},
];

export default function BulkEditActivities({open, onClose, onSubmit, loading, justSubmitted, activities}) {
  const [filter, setFilter] = useState("");
  const [bulkVals, setBulkVals] = useState(_.mapValues(_.keyBy(updateKeys, 'name'), key => ''));
  const [activityVal, setActivityVal] = useState(activities);
  useEffect(() => {
    if (open && justSubmitted) {
      setBulkVals(_.mapValues(_.keyBy(updateKeys, 'name'), key => ''))
      setFilter('')
      onClose();
    }
  }, [justSubmitted, onClose, open])
  
  useEffect(() => {
    const filtered = activities.filter(a => !filter || a.type === filter).map(act => {
      return updateKeys.reduce((aggr, key) => {
        if (bulkVals[key.name]) {
          return {...aggr, [key.name]: parseFloat(bulkVals[key.name])}
        }
        return aggr;
      }, act);
    });

    if (!shallowCompare(filtered, activityVal, updateKeys.map(k => k.name))) {
      setActivityVal(filtered)
    }

  }, [activities, activityVal, filter, bulkVals])

  const classes = useStyles();
  const texts = {
    title: 'עריכת ביצועים מהירה',
    subtitle: 'יש לעדכן את השדות לערכים הרצויים',
    action: 'שמירה',
  }

  function handleSubmit(values) {
    onSubmit(values.activities);
  }
  return (
  <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="submit-activity-title" disableBackdropClick disableEscapeKeyDown={loading}>
    <DialogTitle classes={{root: classes.dialogTitle}} id="submit-activity-title">{texts.title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{texts.subtitle}</DialogContentText>
      {open && (<Formik
        initialValues={{activities: activityVal}}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">סינון</FormLabel>
                  <RadioGroup
                    row
                    aria-label="report-language"
                    name="lang"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <FormControlLabel value="" control={<Radio color="primary" />} label="הכל" />
                    <FormControlLabel value="contract" control={<Radio color="primary" />} label="חוזה" />
                    <FormControlLabel value="additions" control={<Radio color="primary" />} label="תוספות" />
                  </RadioGroup>
                </FormControl>
                </Grid>
                  {updateKeys.map(key => (
                    <Grid key={key.name} item xs={2}>
                      <MuiTextField
                        key={key}
                        label={key.label}
                        size="small"
                        variant="outlined"
                        readOnly
                        margin="dense"
                        fullWidth
                        type="number"
                        onChange={e => setBulkVals({...bulkVals, [key.name]: e.target.value})}
                        InputProps={{readOnly: loading}}
                        inputProps={{step: "0.01"}}
                      />
                    </Grid>
                  ))}
              </Grid>
              <FieldArray
                name="activities"
                render={arrayHelpers => (
                  props.values.activities.map((value, index) => (
                    <Grid container key={index} spacing={1}>
                      <Grid item xs={6}>
                        <MuiTextField
                          size="small"
                          variant="outlined"
                          readOnly
                          value={value.name}
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                      {updateKeys.map(key => (
                        <Grid key={key.name} item xs={2}>
                          <TextField
                            name={`activities[${index}.${key.name}]`}
                            fieldProps={{
                              label: key.label,
                              type: 'number',
                              InputProps: {readOnly: loading},
                              size: 'small',
                              margin: 'dense',
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ))
                )}
              />
              <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                  ביטול
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {texts.action}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
      )}
    </DialogContent>
  </Dialog>
  );
}