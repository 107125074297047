import React, {useEffect, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, CircularProgress, DialogTitle, Typography,
} from '@material-ui/core';
import {Formik} from 'formik';
import TextField from 'components/Form/TextField';
import Yup from 'services/yup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import { priceFormatter } from 'utils/formatters';

const fieldsValidations = {
  amount: Yup.number().required('זהו שדה חובה'),
  vat: Yup.number().required('זהו שדה חובה'),
  rate: Yup.number().required('זהו שדה חובה'),
  date: Yup.string(),
};

const validationSchema = Yup.object().shape(fieldsValidations);
const emptyProj = _.transform(_.keys(fieldsValidations), (aggr, key) => aggr[key] = '', {});

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  }
}));

export default function CreateEditPayment({open, onClose, onSubmit, loading, justSubmitted, payment = _.clone(emptyProj)}) {
  const [paymentVal, setPaymentVal] = useState(_.clone(payment || emptyProj));
  useEffect(() => {
    if (open && justSubmitted) {
      onClose();
    }
  }, [justSubmitted, onClose, open])
  
  useEffect(() => {
    if (!_.isEqual(paymentVal, payment)) {
      setPaymentVal(_.clone(payment || emptyProj))
    }
  }, [payment, paymentVal])

  const classes = useStyles();
  const texts = {
    title: paymentVal.id ? `עריכת תשלום` : 'יצירת תשלום חדש',
    subtitle: paymentVal.id ? 'עריכת פרטי התשלום:' : 'כדי ליצור תשלום חדש יש למלא את הפרטים הבאים:',
    action: paymentVal.id ? 'עריכה' : 'יצירה',
  }
  return (
  <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="submit-payment-title" disableBackdropClick disableEscapeKeyDown={loading}>
    <DialogTitle classes={{root: classes.dialogTitle}} id="submit-payment-title">{texts.title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{texts.subtitle}</DialogContentText>
      {open && (<Formik
        initialValues={paymentVal}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {handleSubmit} = props;
          const {amount, vat} = props.values;
          const totalToPay = (amount * (100 + vat) / 100);
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                name="amount"
                fieldProps={{
                  label: 'סכום',
                  type: 'number',
                  autoFocus: true,
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="vat"
                fieldProps={{
                  label: 'מע"מ',
                  type: "number",
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="rate"
                fieldProps={{
                  label: 'שער',
                  type: 'number',
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="date"
                fieldProps={{
                  label: 'תאריך',
                  InputProps: {readOnly: loading},
                }}
              />
              {totalToPay && <Typography>
                סה"כ לתשלום: <Typography component="span" variant="h6">{priceFormatter(totalToPay)}</Typography>
              </Typography>}
              <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                  ביטול
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {texts.action}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
      )}
    </DialogContent>
  </Dialog>
  );
}