const config = {
    idleThrottle: 5,
    logoutThrottle: 1000,
    sessionIdleExpiration: 60 * 20,
    heartbitPollingTime: 5 * 60 * 1000,
    loginPaths: ['/login'],
    tinyEditorApiKey: 'gacqw6fuxqw8b2m3y7mp4ntch6z7kw1jrvrr1h61pv65aiid',
    minPassLength: 6,

};
export default config;
