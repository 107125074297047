import {ActionTypes} from './actions';

const getFailureMessage = (action) => {
    const errorCode = (action.meta && action.meta.key) || action.payload.code;

    switch (errorCode) {
    case 'auth/user-not-found':
        return 'login_notFound';
    case 'auth/wrong-password':
        return 'login_mismatch';
    case 'auth/invalid-email':
        return 'login_invalidEmail';
    case 'auth/invalid-password':
    case 'auth/email-already-exists':
        return 'login_emailExists';
    case 'auth/invalid-action-code':
    case 'auth/expired-action-code':
        return 'login_invalidCode';
    default:
        return 'login_failed'; 
    }
}

const authReducer = (state = {}, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.LOGIN_REQUEST:
        nextState = {...state, loading: true, loginFailureMessage: false};
        break;
    case ActionTypes.LOGIN_SUCCESS:
        nextState = {...state, user: payload, isLoggedIn: true, loading: false, authComplete: true};
        break;
    case ActionTypes.LOGOUT_SUCCESS:
        nextState = {isLoggedIn: false, authComplete: true};
        break;
    case ActionTypes.LOGIN_FAILURE:
        nextState = {...state, isLoggedIn: false, loading: false, loginFailureMessage: getFailureMessage(action)};
        break;
    default:
        nextState = state;
    }
    return nextState;
};

export default authReducer;
