import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'LOGIN_ACTION',
    'LOGIN_REQUEST',
    'LOGIN_SUCCESS',
    'LOGIN_FAILURE',

    'LOGOUT_ACTION',
    'LOGOUT_SUCCESS',

    'ACTIVITY_DETECTED',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
