import React, {useEffect, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, CircularProgress, DialogTitle, Grid,
} from '@material-ui/core';
import {Formik} from 'formik';
import TextField from 'components/Form/TextField';
import AutoCompleteField from 'components/Form/AutoCompleteField';
import Yup from 'services/yup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'

const required = Yup.string().required('זהו שדה חובה');
const yupPositiveField = Yup.number().min(0, 'חייב להיות גדול או שווה ל-0').required('זהו שדה חובה');
const yupPercentField = yupPositiveField.max(100, 'חייב להיות קטן או שווה ל-100')
const fieldsValidations = {
  name: required,
  unit_desc: required,
  type: required,
  quantity: required,
  price: required,
  discount: yupPercentField,
  completed: yupPercentField,
  lien: yupPercentField,
};

const validationSchema = Yup.object().shape(fieldsValidations);
const emptyProj = _.transform(_.keys(fieldsValidations), (aggr, key) => aggr[key] = '', {});

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  }
}));

export default function CreateEditActivity({open, onClose, onSubmit, loading, justSubmitted, activity = _.clone(emptyProj)}) {
  const [activityVal, setActivityVal] = useState(_.clone(activity || emptyProj));
  useEffect(() => {
    if (open && justSubmitted) {
      onClose();
    }
  }, [justSubmitted, onClose, open])
  
  useEffect(() => {
    if (!_.isEqual(activityVal, activity)) {
      setActivityVal(_.clone(activity || emptyProj))
    }
  }, [activity, activityVal])

  const classes = useStyles();
  const texts = {
    title: activityVal.id ? `עריכת ביצוע` : 'יצירת ביצוע חדש',
    subtitle: activityVal.id ? 'עריכת פרטי הביצוע:' : 'כדי ליצור ביצוע חדש יש למלא את הפרטים הבאים:',
    action: activityVal.id ? 'עריכה' : 'יצירה',
  }
  return (
  <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="submit-activity-title" disableBackdropClick disableEscapeKeyDown={loading}>
    <DialogTitle classes={{root: classes.dialogTitle}} id="submit-activity-title">{texts.title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{texts.subtitle}</DialogContentText>
      {open && (<Formik
        initialValues={activityVal}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {handleSubmit} = props;
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                fieldProps={{
                  label: 'תיאור',
                  autoFocus: true,
                  InputProps: {readOnly: loading},
                }}
              />
              <AutoCompleteField
                name="type"
                label="סוג"
                options={[{value: 'contract', label: 'חוזה'}, {value: 'additions', label: 'תוספות'}]}
                fieldProps={{
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="quantity"
                fieldProps={{
                  label: 'כמות',
                  type: 'number',
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="unit_desc"
                fieldProps={{
                  label: 'יחידה',
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="price"
                fieldProps={{
                  label: 'מחיר',
                  type: 'number',
                  InputProps: {readOnly: loading},
                }}
              />
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    name="discount"
                    fieldProps={{
                      label: '(%) הנחה',
                      type: 'number',
                      InputProps: {readOnly: loading},
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="lien"
                    fieldProps={{
                      label: '(%) עיכבון',
                      type: 'number',
                      InputProps: {readOnly: loading},
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="completed"
                    fieldProps={{
                      label: '(%) ביצוע',
                      type: 'number',
                      InputProps: {readOnly: loading},
                    }}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                  ביטול
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {texts.action}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
      )}
    </DialogContent>
  </Dialog>
  );
}