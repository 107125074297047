import {connect} from 'utils/reduxUtils';
import Component from './BulkAddActivities';
import * as PSSelectors from 'modules/posts/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onSubmit: Actions.ACTIVITY_BULK_ADD_FORM_SUBMITTED,
};

const mapStateToProps = state => {
    return {
        loading: PSSelectors.isSubmitting(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
