import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
} from '@material-ui/core';
import { withRouter } from "react-router-dom";
import {Alert} from '@material-ui/lab';

import useStyles from "./styles";

import logo from "images/logo.png";

function getGreeting() {
  const now = new Date();
  const isSummer = now.getTimezoneOffset() === -180;
  const eveningTime = isSummer ? 19 : 17;
  const hour = now.getHours();
  if (hour < 5 || hour > 21) {
    return 'לילה טוב!'
  }
  if (hour < 12) {
    return 'בוקר טוב!'
  }
  if (hour < eveningTime) {
    return 'צהריים טובים!'
  }
  return 'ערב טוב!'
}

function Login({loading, error, onSubmit}) {
  var classes = useStyles();

  // local
  var [activeTabId, setActiveTabId] = useState(0);
  var [username, setUsernameValue] = useState("");
  var [password, setPasswordValue] = useState("");
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <form className={classes.form} onSubmit={(e) => {
          e.preventDefault();
          onSubmit({username, password});
        }}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="התחברות" classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            <React.Fragment>
              <Typography variant="h2" className={classes.greeting}>
                {getGreeting() + ' ברוך שובך למערכת'}
              </Typography>
              <TextField
                id="username"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={username}
                onChange={e => setUsernameValue(e.target.value)}
                margin="normal"
                label="שם משתמש"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={password}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                label="סיסמה"
                type="password"
                fullWidth
              />
              {error &&
                (<Alert severity="error">שם משתמש וסיסמה לא תואמים</Alert>)
              }
              <div className={classes.formButtons}>
                {loading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      username.length === 0 || password.length === 0
                    }
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    התחברות
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
        </form>
        <Typography color="primary" className={classes.copyright}>
          פיתוח ועיצוב - רועי נגר
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
