import {connect} from 'utils/reduxUtils';
import Component from './SuppliersPage';
import * as SuppliersSelectors from 'modules/suppliers/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onMount: Actions.SUPPLIERS_PAGE_MOUNTED,
    onShowHiddenChange: Actions.SUPPLIERS_PAGE_SHOW_ARCHIVED_CHANGE,
    onSupplierChangeState: Actions.SUPPLIERS_PAGE_TOGGLE_SUPPLIER_STATE,
};

const mapStateToProps = state => {
    return {
        loading: SuppliersSelectors.isLoading(state),
        justSubmitted: SuppliersSelectors.justSubmitted(state),
        suppliers: SuppliersSelectors.suppliers(state),
        supplierProjects: SuppliersSelectors.supplierProjects(state),
        filter: SuppliersSelectors.filter(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
