import React from "react";
import ReactDOM from "react-dom";
import {Provider as ReduxProvider} from 'react-redux';
import store, { history } from './store/store'
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from '@material-ui/core';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { ConnectedRouter } from 'connected-react-router'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

ReactDOM.render(
  <ReduxProvider store={store}>
    <LayoutProvider>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </ThemeProvider>
      </StylesProvider>
    </LayoutProvider>
  </ReduxProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
