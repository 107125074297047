import React from 'react';
import {
  Button, CircularProgress, Grid, Paper
} from '@material-ui/core';
import {Formik} from 'formik';
import TextField from 'components/Form/TextField';
import Yup from 'services/yup';
import {
  Settings as SettingsIcon,
} from '@material-ui/icons';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "components/Loader/Loader";
import { makeStyles } from "@material-ui/styles";


const yupPositiveField = Yup.number().min(0, 'חייב להיות גדול או שווה ל-0').required('זהו שדה חובה');
const validationSchema = Yup.object().shape({
  vat: yupPositiveField,
});
const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  paper: {
    padding: theme.spacing(4),
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function SettingsPage({settings, loading, submitting, onSubmit}) {
  const classes = useStyles();
  return (
    <>
      <PageTitle title="הגדרות" icon={SettingsIcon} />
      <Grid container>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Loader loading={loading} />
            {!loading && <Formik
              enableReinitialize
              initialValues={settings}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {handleSubmit} = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      name="vat"
                      fieldProps={{
                        label: 'ערך מע"מ',
                        autoFocus: true,
                        type: 'number',
                        InputProps: {readOnly: submitting},
                      }}
                    />
                    <div className={classes.wrapper}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitting}
                      >
                        שמירה
                      </Button>
                      {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  </form>
                );
              }}
            </Formik>}
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
