import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {
  Add as AddIcon
} from '@material-ui/icons';

// components
import { priceFormatter } from "utils/formatters";
import { muiDataTableLabels } from "utils/i18nUtils";
import CreateEditPayment from "components/CreateEditPayment";
import useStyles from "./styles";


function PSPaymentsToolbar({onShowArchivedChange, onNewPayment}) {
  return (
    <>
      <Tooltip arrow title="הוסף תשלום חדש">
        <IconButton aria-label="הוסף תשלום חדש" onClick={onNewPayment}>
          <AddIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  );
}
export default function PSPayments(props) {
    const {payments} = props;
    const [editModalState, setEditModalState] = useState({open: false});
    const classes = useStyles();
    useEffect(() => {
      if (props.justSubmitted) {
        setEditModalState({open: false})
      }
    }, [props.justSubmitted])
    const cellOptions = {setCellProps: () => ({className: classes.tableCell})}
    return (
    <>
      <MUIDataTable 
        title="תשלומים"
        data={payments}
        columns={[
          {name: 'id', options: {display: 'false'}},
          {name: 'amount', label: 'סכום', options: {...cellOptions, customBodyRender: p => priceFormatter(p, false)}},
          {name: 'vat', label: 'מע"מ', options: cellOptions},
          {name: 'total', label: 'סה"כ', options: {...cellOptions, customBodyRender: p => priceFormatter(p, false)}},
          {name: 'rate', label: 'שער', options: cellOptions},
          {name: 'date', label: 'תאריך', options: cellOptions},
        ]}
        options={{
          filterType: "textField",
          textLabels: muiDataTableLabels,
          sortOrder: {name: 'id', direction: 'asc'},
          onCellClick: (_, {dataIndex}) => setEditModalState({open: true, payment: payments[dataIndex]}),
          selectableRows: 'none',
          pagination: false,
          filter: false,
          print: false,
          download: false,
          viewColumns: false,
          search: false,
          selectableRowsOnClick: true,
          responsive: 'standard',
          customToolbar: () => <PSPaymentsToolbar onNewPayment={() => setEditModalState({open: true})} />,
          tableBodyMaxHeight: '235px'
        }}
      />
      <CreateEditPayment {...editModalState} onClose={() => setEditModalState({open: false})} />
    </>
    );
  }