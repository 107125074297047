import {ActionTypes as AuthActionTypes} from 'modules/auth/actions';
import {push, replace} from 'connected-react-router';
import {Selectors as NavigationSelectors} from 'modules/navigation';
import Config from 'config';

const handleLogin = ({getState, dispatch}) => {
    const pathname = NavigationSelectors.pathnameSelector(getState());
    const query = NavigationSelectors.querySelector(getState());
    if (query.return) {
        dispatch(replace(query.return));
    } else if (Config.loginPaths.includes(pathname)) {
        dispatch(replace('/'));
    }
}

const navigationController = store => next => action => {
    const result = next(action);

    const {type} = action;

    switch (type) {
    case AuthActionTypes.LOGIN_SUCCESS:
        handleLogin(store);
        break;
    case AuthActionTypes.LOGOUT_SUCCESS: {
        const pathname = NavigationSelectors.pathnameSelector(store.getState());
        if (!Config.loginPaths.includes(pathname)) {
            const query = pathname === '/' ? '' : `?return=${encodeURIComponent(pathname)}`;
            store.dispatch(push(`/login${query}`));
        }
        break;
    }
    default:
        break;
    }

    return result;
}

export default navigationController;
