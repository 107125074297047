import React from "react";
import { Button } from '@material-ui/core';

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleRoot}>
      <div className={classes.titleWrapper}>
        {props.icon && React.createElement(props.icon, {className: classes.icon})}
        <Typography className={classes.typo} variant="h1" size="sm">
          {props.title}
        </Typography>
      </div>
      {props.button && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="secondary"
        >
          {props.button}
        </Button>
      )}
    </div>
  );
}
