import { createSelector } from 'reselect';
import * as NavSelectors from 'modules/navigation/selectors';
import * as AuthSelectors from 'modules/auth/selectors';

export const sliceSelector = (state) => state.projects;
export const projects = createSelector(sliceSelector, slice => slice.projects || []);
export const selectedProject = createSelector(sliceSelector, slice => slice.selectedProject);
export const isLoading = createSelector(sliceSelector, slice => !!slice.loading);
export const isSubmitting = createSelector(sliceSelector, slice => !!slice.submitting);
export const justSubmitted = createSelector(sliceSelector, slice => !!slice.justSubmitted);
export const submitError = createSelector(sliceSelector, slice => slice.submitError);

export const filter = createSelector(sliceSelector, slice => slice.filter);
export const singleFilter = createSelector(sliceSelector, slice => slice.singleFilter);
export const selectedSupplier = createSelector(selectedProject, project => project.supplier);
export const ownProjects = createSelector(projects, AuthSelectors.user, (projects, user) => projects.filter(p => user && user.ten === p.tenantId));

export const matchingProjectId = createSelector(
    NavSelectors.pathnameSelector,
    (pathname) => {
        const matchedRegex = (pathname || '').match(/\/projects\/([^/?]*)/) || [];
        const id = matchedRegex[1];
        return id;
    }
);
export const matchingSupplierId = createSelector(
    NavSelectors.pathnameSelector,
    (pathname) => {
        const matchedRegex = (pathname || '').match(/\/projects\/[^/?]*\/suppliers\/([^/?]*)/) || [];
        const id = matchedRegex[1];
        return parseInt(id);
    }
);