import React, {useState, useEffect} from 'react';
import {Field, useFormikContext, useField } from 'formik';
import MuiTextField from '@material-ui/core/TextField';
import {Autocomplete as FormikAutoComplete} from 'formik-material-ui-lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles(theme => {

  return {
    paper: {
      background: theme.palette.primary.extraLight,
    },
  }
});


export default function AutoCompleteAsync({fieldProps = {}, name, label, fetchOptionsAsync, ...props}) {
  const [open, setOpen] = useState(false);
  const [field] = useField(name);
  const [options, setOptions] = React.useState([]);
  const [loadInitialValues, setLoadInitialValues] = React.useState(!_.isEmpty(field.value));
  const context = useFormikContext();
  const error = (context.touched[name] && context.errors[name]);
  const loading = loadInitialValues || (open && options.length === 0);
  const classes = useStyles();

  useEffect(() => {
    let active = true;
    
    if (!loading) {
      return undefined;
    }

    (async () => {
      const items = await fetchOptionsAsync();

      if (active) {
        setLoadInitialValues(false)
        setOptions(items);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, fetchOptionsAsync, loadInitialValues, setLoadInitialValues, field]);

  return (
    <Field
      name={name}
      id={name}
      component={FormikAutoComplete}
      options={options.map(opt => opt.id)}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(id) => {
        const option = _.find(options, {id})
        return _.get(option, 'name', id);
      }}
      getOptionSelected={(option, value) =>  option === value}
      fullWidth
      loading={loading}
      multiple
      classes={{paper: classes.paper}}
      renderInput={(params) => (
        <MuiTextField 
          error={!!error}
          margin="normal"
          fullWidth
          {...fieldProps}
          variant="outlined"
          label={label}
          helperText={error || fieldProps.helperText}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      {...props}
    />
  );
}