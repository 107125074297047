import { createStore, applyMiddleware, compose } from 'redux'
import {getRootReducer, rootSaga} from 'modules'
import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory} from 'history'
import { routerMiddleware } from 'connected-react-router'
import navigationController from 'navigation/navigationController';

export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const initialState = {}
const middleware = [
  sagaMiddleware,
  routerMiddleware(history),
  navigationController,
]

let composeEnhancers = compose;
const enableRedux = process.env.NODE_ENV === 'development' || !!sessionStorage.getItem('enableRedux');
if (enableRedux && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const composedEnhancers = composeEnhancers(
  applyMiddleware(...middleware),
)

const store = createStore(
  getRootReducer({history}),
  initialState,
  composedEnhancers
)

sagaMiddleware.run(rootSaga);

store.dispatch({type: 'STORE_LOADED'});

export default store
