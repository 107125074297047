import {connect} from 'utils/reduxUtils';
import Component from './CreateEditSupplier';
import * as SuppliersSelectors from 'modules/suppliers/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onSubmit: Actions.SUPPLIER_FORM_SUBMITTED,
};

const mapStateToProps = state => {
    return {
        loading: SuppliersSelectors.isSubmitting(state),
        submitError: SuppliersSelectors.submitError(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
