import {connect} from 'utils/reduxUtils';
import Component from './SettingsPage';
import * as SettingsSelectors from 'modules/settings/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onSubmit: Actions.SETTINGS_PAGE_SUBMIT,
};

const mapStateToProps = state => {
    return {
        loading: SettingsSelectors.isLoading(state),
        submitting: SettingsSelectors.isSubmitting(state),
        settings: SettingsSelectors.settings(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
