import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  },
  dialogContent: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    paddingInlineEnd: theme.spacing(5),
  },
  bgError: {
    background: theme.palette.error.main,
  },
  btnError: {
    background: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
      boxShadow: theme.customShadows.widgetWide,
    },
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
  }
}));
