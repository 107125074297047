import {put, takeLatest, call} from 'redux-saga/effects';
import {Actions} from 'modules/shares/actions';
import axios from 'services/axios';
import {ActionTypes as PagesActions} from 'pages/actions';
import _ from 'lodash';


function* loadShares(action) {
    yield put(Actions.LOAD_SHARES_REQUEST());
    try {
        const {data} = yield call(axios.get, `shares`);
        yield put(Actions.LOAD_SHARES_SUCCESS(data));
    } catch (err) {
        yield put(Actions.LOAD_SHARES_FAILURE(err));
    }
}

function* submitShareForm(action) {
    yield put(Actions.SHARE_FORM_SUBMIT_REQUEST());
    try {
        const {payload} = action;
        const shareId = payload.id;
        const fields = _.pick(payload, ['projectIds', 'username']);
        const method = shareId ? axios.patch : axios.post;
        const url = shareId ? `shares/${shareId}` : `shares`;
        const result = yield call(method, url, fields);

        yield put(Actions.SHARE_FORM_SUBMIT_SUCCESS(result.data.items));
    } catch (err) {
        yield put(Actions.SHARE_FORM_SUBMIT_FAILURE(err));
    }
}

function* deleteShare(action) {
    yield put(Actions.DELETE_SHARE_SUBMIT_REQUEST());
    try {
        const {id} = action.payload;
        const result = yield call(axios.post, `shares/delete`, {ids: [id]});

        yield put(Actions.DELETE_SHARE_SUBMIT_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.DELETE_SHARE_SUBMIT_FAILURE(err));
    }
}

const sagas = [
    takeLatest([PagesActions.SHARES_PAGE_MOUNTED], loadShares),
    takeLatest([PagesActions.SHARE_FORM_SUBMITTED], submitShareForm),
    takeLatest([PagesActions.SHARES_PAGE_DELETE_SHARE], deleteShare),
]
export default sagas;
