import {connect} from 'utils/reduxUtils';
import Component from './PSPayments';
import * as ProjectsSelectors from 'modules/projects/selectors';
import * as PSSelectors from 'modules/posts/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onRefreshRates: Actions.PS_REFRESH_RATES_CLICKED,
};

const mapStateToProps = state => {
    return {
        // project: ProjectsSelectors.selectedProject(state),
        // payments: PSSelectors.payments(state),
        // justSubmitted: PSSelectors.justSubmitted(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
