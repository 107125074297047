const nf = new Intl.NumberFormat();

export const priceFormatter = (price, round = true) => {
    if (isNaN(price)) {
        return '';
    }
    const rounded = round ? Math.round(price) : price;
    return nf.format(rounded)
}

const units = {
    year  : 24 * 60 * 60 * 1000 * 365,
    month : 24 * 60 * 60 * 1000 * 365/12,
    day   : 24 * 60 * 60 * 1000,
    hour  : 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000
}

var rtf = new Intl.RelativeTimeFormat('he', { numeric: 'auto' })
export const relativeDateFormatter = (d1, d2 = Date.now()) => {
    if (!d1) {
        return '<לא ידוע>';
    }
    const elapsed = d1 - d2
    // "Math.abs" accounts for both "past" & "future" scenarios
    for (var u in units) {
        if (Math.abs(elapsed) > units[u] || u === 'second') {
            return rtf.format(Math.round(elapsed/units[u]), u)
        }
    }
}

export const formatDateTime = (date) => {
    if (!date) {
        return null;
    }
    return new Date(date).toLocaleString(navigator.languages, {hour12: false})
}