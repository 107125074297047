import React from "react";
import {CircularProgress} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  loaderRoot: {
    textAlign: 'center'
  },
}));


export default function Loader({children, loading}) {
  const classes = useStyles();

  if (!loading) {
      return children || null;
  }
  return (
    <div
      className={classes.loaderRoot}
    >
      <CircularProgress />
    </div>
  );
}
