import {connect} from 'utils/reduxUtils';
import Component from './CreateEditPost';
import * as ProjectsSelectors from 'modules/projects/selectors';
import {fetchTaxonomyTerms} from 'modules/posts/autocomplete';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onSubmit: Actions.CREATE_EDIT_POST_FORM_SUBMITTED,
};

const mapStateToProps = state => {
    return {
        loading: ProjectsSelectors.isSubmitting(state),
        submitError: ProjectsSelectors.submitError(state),
        projectId: ProjectsSelectors.matchingProjectId(state),
        getTaxonomyTerms: fetchTaxonomyTerms
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
