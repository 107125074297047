import {connect} from 'utils/reduxUtils';
import Component from './Header';
import * as AuthSelectors from 'modules/auth/selectors';
import * as ProjectsSelectors from 'modules/projects/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onLogout: Actions.HEADER_LOGOUT_REQUESTED,
    onSearchProject: Actions.HEADER_SEARCH_PROJECT_FOCUSED, 
};

const mapStateToProps = state => {
    return {
        user: AuthSelectors.user(state),
        projects: ProjectsSelectors.projects(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
