import {put, takeLatest, call, select} from 'redux-saga/effects';
import {Actions} from 'modules/suppliers/actions';
import * as Selectors from 'modules/suppliers/selectors';
import axios from 'services/axios';
import {ActionTypes as PagesActions} from 'pages/actions';
import _ from 'lodash';

function* loadSuppliers(action) {
    yield put(Actions.LOAD_SUPPLIERS_REQUEST());
    try {
        const {showArchived} = yield select(Selectors.filter);
        const query = `active=${!showArchived}`;
        const {data} = yield call(axios.get, `suppliers?${query}`);
        yield put(Actions.LOAD_SUPPLIERS_SUCCESS(data));
    } catch (err) {
        yield put(Actions.LOAD_SUPPLIERS_FAILURE(err));
    }
}

function* submitSupplierForm(action) {
    yield put(Actions.SUPPLIER_FORM_SUBMIT_REQUEST());
    try {
        const {payload} = action;
        const projectId = payload.id;
        const fields = _.pick(payload, ['name', 'phone', 'email', 'location', 'bank']);
        const method = projectId ? axios.patch : axios.post;
        const url = projectId ? `suppliers/${projectId}` : `suppliers`;
        const result = yield call(method, url, fields);

        yield put(Actions.SUPPLIER_FORM_SUBMIT_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.SUPPLIER_FORM_SUBMIT_FAILURE(err));
    }
}

function* loadSupplierProjects(action) {
    yield put(Actions.LOAD_SUPPLIER_PROJECTS_REQUEST());
    try {
        const supplierId = action.payload.id;
        const response = yield call(axios.get, `suppliers/${supplierId}/projects`);
        yield put(Actions.LOAD_SUPPLIER_PROJECTS_SUCCESS(response.data));
    } catch (err) {
        yield put(Actions.LOAD_SUPPLIER_PROJECTS_FAILURE(err));
    }
}

function* changeSupplierState(action) {
    yield put(Actions.SUPPLIER_CHANGE_STATE_REQUEST());
    try {
        const {payload} = action;
        const result = yield call(axios.patch, `suppliers/${payload.id}`, {active: !payload.active});

        yield put(Actions.SUPPLIER_CHANGE_STATE_SUCCESS(result.data));
    } catch (err) {
        yield put(Actions.SUPPLIER_CHANGE_STATE_FAILURE(err));
    }
}

const sagas = [
    takeLatest([PagesActions.SUPPLIERS_PAGE_MOUNTED, PagesActions.SUPPLIERS_PAGE_SHOW_ARCHIVED_CHANGE], loadSuppliers),
    takeLatest([PagesActions.SUPPLIER_FORM_SUBMITTED], submitSupplierForm),
    takeLatest([PagesActions.SUPPLIER_PROJECTS_MODAL_CHANGE], loadSupplierProjects),
    takeLatest(PagesActions.SUPPLIERS_PAGE_TOGGLE_SUPPLIER_STATE, changeSupplierState),
]
export default sagas;
