import React, {useEffect, useState} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, CircularProgress, DialogTitle,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

import {Formik} from 'formik';
import TextField from 'components/Form/TextField';
import Yup from 'services/yup';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'

const fieldsValidations = {
  name: Yup.string().required('זהו שדה חובה'),
  baseUrl: Yup.string().url('יש להכניס כתובת אתר חוקית'),
  apiKey: Yup.string(),
};

const validationSchema = Yup.object().shape(fieldsValidations);
const emptyProj = _.transform(_.keys(fieldsValidations), (aggr, key) => aggr[key] = '', {});

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  }
}));

export default function CreateEditProject({open, onClose, onSubmit, submitError, loading, justSubmitted, project = _.clone(emptyProj)}) {
  const [projectVal, setProjectVal] = useState(_.clone(project || emptyProj));
  useEffect(() => {
    if (open && justSubmitted) {
      onClose();
    }
  }, [justSubmitted, onClose, open])
  
  useEffect(() => {
    if (!_.isEqual(projectVal, project)) {
      setProjectVal(_.clone(project || emptyProj))
    }
  }, [project, projectVal])

  const classes = useStyles();
  const texts = {
    title: projectVal.id ? `עריכת פרויקט - ${project.name}` : 'יצירת פרויקט חדש',
    subtitle: projectVal.id ? 'עריכת פרטי הפרויקט:' : 'כדי ליצור פרויקט חדש יש למלא את הפרטים הבאים:',
    action: projectVal.id ? 'עריכה' : 'יצירה',
  }
  return (
  <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="submit-project-title" disableBackdropClick disableEscapeKeyDown={loading}>
    <DialogTitle classes={{root: classes.dialogTitle}} id="submit-project-title">{texts.title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{texts.subtitle}</DialogContentText>
      {open && (<Formik
        initialValues={projectVal}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {handleSubmit} = props;
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                name="name"
                fieldProps={{
                  label: 'שם הפרויקט',
                  autoFocus: true,
                  InputProps: {readOnly: loading},
                }}
              />
              <TextField
                name="baseUrl"
                fieldProps={{
                  label: 'דומיין',
                  InputProps: {readOnly: loading, placeholder: 'https://example.com'},
                }}
              />
              <TextField
                name="apiKey"
                fieldProps={{
                  label: 'מפתח אבטחה',
                  InputProps: {readOnly: loading},
                }}
              />
              {submitError && <Alert severity="error">{submitError}</Alert>}
              <DialogActions>
                <Button onClick={onClose} disabled={loading}>
                  ביטול
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {texts.action}
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </DialogActions>
            </form>
          );
        }}
      </Formik>
      )}
    </DialogContent>
  </Dialog>
  );
}