import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const projectsPageActions = [
    'PROJECTS_PAGE_MOUNTED',
    'PROJECTS_PAGE_DELETE_PROJECT',

    'SUPPLIERS_PAGE_MOUNTED',
    'SUPPLIERS_PAGE_SHOW_ARCHIVED_CHANGE',
    'SUPPLIERS_PAGE_TOGGLE_SUPPLIER_STATE',
    
    'LOGIN_FORM_SUBMITTED',
    'HEADER_LOGOUT_REQUESTED',
    'HEADER_SEARCH_PROJECT_FOCUSED',

    'S_PROJECT_PAGE_MOUNTED',
    'S_PROJECT_PAGE_TOGGLE_SUPPLIER_STATE',
    'S_PROJECT_PAGE_DOWNLOAD_REPORT',
    
    'PROJECT_SUP_PAGE_MOUNTED',
    'PROJ_SUP_PAGE_DOWNLOAD_REPORT',
    'PROJ_SUP_PAGE_TOGGLE_ACTIVITY_STATE',
    
    'PROJECT_FORM_SUBMITTED',
    'CREATE_EDIT_POST_FORM_SUBMITTED',

    'SUPPLIER_FORM_SUBMITTED',
    
    'PS_REFRESH_RATES_CLICKED',
    
    'PAYMENTS_SECTION_MOUNTED',
    'PAYMENT_FORM_SUBMITTED',
    
    'ACTIVITY_FORM_SUBMITTED',
    'ACTIVITY_BULK_EDIT_FORM_SUBMITTED',
    'ACTIVITY_BULK_ADD_FORM_SUBMITTED',
    'ACTIVITIES_ARCHIVED_STATE_TOGGLED',

    'SUPPLIER_PROJECTS_MODAL_CHANGE',

    'SETTINGS_PAGE_SUBMIT',

    'SHARES_PAGE_MOUNTED',
    'SHARE_FORM_SUBMITTED',
    'SHARES_PAGE_DELETE_SHARE',

    'PUBLISH_SINGLE_POST_ACTION',
    'SAVE_AND_PUBLISH_POST_ACTION',
];

const allActions = [
    ...projectsPageActions
]
export const ActionTypes = createActionTypes(allActions);
export const Actions = createActionCreators(allActions);
