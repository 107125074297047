import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'LOAD_SHARES_REQUEST',
    'LOAD_SHARES_SUCCESS',
    'LOAD_SHARES_FAILURE',

    'SHARE_FORM_SUBMIT_REQUEST',
    'SHARE_FORM_SUBMIT_SUCCESS',
    'SHARE_FORM_SUBMIT_FAILURE',

    'DELETE_SHARE_SUBMIT_REQUEST',
    'DELETE_SHARE_SUBMIT_SUCCESS',
    'DELETE_SHARE_SUBMIT_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
