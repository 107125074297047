import {put, takeLatest, call} from 'redux-saga/effects';
import {Actions} from 'modules/settings/actions';
import axios from 'services/axios';
import {ActionTypes as AuthActionTypes} from 'modules/auth/actions';
import {ActionTypes as PagesActions} from 'pages/actions';
import _ from 'lodash';

function* loadSettings(action) {
    yield put(Actions.LOAD_SETTINGS_REQUEST());
    try {
        const response = yield call(axios.get, `settings`);
        yield put(Actions.LOAD_SETTINGS_SUCCESS(response.data));
    } catch (err) {
        yield put(Actions.LOAD_SETTINGS_FAILURE(err));
    }
}

function* updateSettings(action) {
    yield put(Actions.UPDATE_SETTINGS_REQUEST());
    try {
        const settings = _.pick(action.payload, ['vat']);
        const response = yield call(axios.patch, `settings`, settings);
        yield put(Actions.UPDATE_SETTINGS_SUCCESS(response.data));
    } catch (err) {
        yield put(Actions.UPDATE_SETTINGS_FAILURE(err));
    }
}

const sagas = [
    takeLatest([PagesActions.SETTINGS_PAGE_SUBMIT], updateSettings),
]
export default sagas;
