import {connect} from 'utils/reduxUtils';
import Component from './SharesPage';
import * as SharesSelectors from 'modules/shares/selectors';
import * as ProjectsSelectors from 'modules/projects/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onMount: Actions.SHARES_PAGE_MOUNTED,
    onDeleteShare: Actions.SHARES_PAGE_DELETE_SHARE,
};

const mapStateToProps = state => {
    return {
        loading: SharesSelectors.isLoading(state),
        justSubmitted: SharesSelectors.justSubmitted(state),
        submitting: SharesSelectors.isSubmitting(state),
        shares: SharesSelectors.enhancedShares(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
