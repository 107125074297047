import React, {useEffect} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import {muiDataTableLabels} from 'utils/i18nUtils'
import { makeStyles } from '@material-ui/core/styles';
import Loader from 'components/Loader/Loader';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.background.light
  }
}));

export default function SupplierProjectsModal({open, onClose, projects, loading, onSupplierChange, supplier = {}}) {  
  useEffect(() => {
    if (open && supplier.id) {
      onSupplierChange(supplier);
    }
  }, [open, supplier, onSupplierChange])

  const classes = useStyles();
  const history = useHistory();

  const texts = {
    title: `רשימת פרויקטים למאמר - ${supplier.name}`,
    action: 'סגירה',
  }

  function onProjectClick(_data, {dataIndex}) {
    const project = projects[dataIndex];
    history.push(`/app/projects/${project.id}`)
  }
  return (
  <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="supplier-projects-title">
    <DialogTitle classes={{root: classes.dialogTitle}} id="supplier-projects-title">{texts.title}</DialogTitle>
    <DialogContent dividers>
      {open && (
        <>
          <Loader loading={loading} />
          {!loading && <MUIDataTable
            data={projects}
            columns={[
              {name: 'name', label: 'שם הפרויקט'},
              {name: 'details', label: 'פרטי הפרויקט'},
            ]}
            options={{
              filterType: "textField",
              elevation: 0,
              onCellClick: onProjectClick,
              textLabels: muiDataTableLabels,
              selectableRows: 'none',
              filter: false,
              search: false,
              searchOpen: true,
              print: false,
              download: false,
              pagination: false,
              viewColumns: false,
              selectableRowsOnClick: true,
              responsive: 'standard',
              tableBodyMaxHeight: '700px',
            }}
          />}
          <DialogActions>
            <Button
              onClick={onClose}
              variant="contained"
              color="primary"
            >
              {texts.action}
            </Button>
          </DialogActions>
        </>
      )}
    </DialogContent>
  </Dialog>
  );
}
