import {createActionTypes, createActionCreators} from 'utils/reduxUtils';

const typesArray = [
    'LOAD_SUPPLIERS_REQUEST',
    'LOAD_SUPPLIERS_SUCCESS',
    'LOAD_SUPPLIERS_FAILURE',

    'SUPPLIER_FORM_SUBMIT_REQUEST',
    'SUPPLIER_FORM_SUBMIT_SUCCESS',
    'SUPPLIER_FORM_SUBMIT_FAILURE',

    'SUPPLIER_CHANGE_STATE_REQUEST',
    'SUPPLIER_CHANGE_STATE_SUCCESS',
    'SUPPLIER_CHANGE_STATE_FAILURE',

    'LOAD_SUPPLIER_PROJECTS_REQUEST',
    'LOAD_SUPPLIER_PROJECTS_SUCCESS',
    'LOAD_SUPPLIER_PROJECTS_FAILURE',
];

export const ActionTypes = createActionTypes(typesArray);
export const Actions = createActionCreators(typesArray);
