import React, {useState} from 'react';
import {Field, useField } from 'formik';
import _ from 'lodash';
import Dropzone from 'react-dropzone'
import InputLabel from '@material-ui/core/InputLabel';
import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedInput } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        height: 136,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 'none',
    },
    previewWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    previewImg: {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0
    },
    label: {
        background: theme.palette.background.paper,
    }
  }));

function DropZoneContent({value, error}) {
    const classes = useStyles();

    if (value) {
        return (
            <div className={classes.previewWrapper}>
                <img className={classes.previewImg} alt="" src={value} />
                <div className="file-upload__preview-hover">
                    יש לגרור תמונה אחרת (או לחץ) כדי להחליף
                </div>
            </div>
        )
    }
    return (
        <div className="file-upload__placeholder-wrapper">
            <div className="image-upload__placeholder" />
            <div className="image-upload__text">
                יש לגרור לכאן תמונה (או ללחוץ)
            </div>
        </div>
    );
}
export default function DropZone({fieldProps = {}, name, label, options = [], ...props}) {
  const [, meta] = useField(name);
  const error = meta.error;
  const classes = useStyles();
  const [isFocused, setFocus] = useState(false)

  function handleDrop(field, accepted) {
    const acceptedFile = accepted[0];
    if (!acceptedFile) {return;}
    const reader = new FileReader();
    reader.onloadend = () => {
        field.onChange({ target: { name, value: {content: reader.result}} });
    };
    reader.readAsDataURL(acceptedFile);
  }
  
  return (
    <Field
      name={name}
      id={name}
      fullWidth
      {...props}
    >
      {({ field, form, meta }) => (
          <FormControl classes={props.classes} error={!!error} fullWidth color="primary" focused={isFocused} margin="normal">
                <InputLabel variant="outlined" htmlFor="select-multiple-accounts" shrink focused={isFocused} className={classes.label}>
                    {label}
                </InputLabel>
                <OutlinedInput
                    color="primary"
                    inputComponent={Dropzone}
                    notched
                    fullWidth
                    label={label}
                    onFocus={console.log}
                    inputProps={{
                        children: ({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className={classes.wrapper} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
                                <input {...getInputProps()} />
                                <DropZoneContent value={_.get(field, 'value.content', _.get(field, 'value.url'))} />
                            </div>
                        ),
                        onDrop: (accepted, rejected) => handleDrop(field, accepted, rejected)
                    }}
                />
              {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
          </FormControl>
    )}
    </Field>
  );
}