
const accessTokenKey = 'access_token';

export const retrieveAccessToken = async () => {
    return localStorage.getItem(accessTokenKey);
}

export const storeAccessToken = async (accessToken) => {
    localStorage.setItem(accessTokenKey, accessToken);
}

export const clearAccessToken = async () => {
    localStorage.removeItem(accessTokenKey);
}

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}


export const parseAccessToken = (token) => {
  try {
    return JSON.parse(b64DecodeUnicode(token.split('.')[1].toString('utf-8')));
  } catch (e) {
    return null;
  }
};