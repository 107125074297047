import React from 'react';
import { useField } from 'formik';
import MuiTextField from '@material-ui/core/TextField';

export default function TextField({fieldProps = {}, ...props}) {
    const [field, meta] = useField(props);
    return(
        <MuiTextField
            error={!!(meta.touched && meta.error)}
            margin="normal"
            fullWidth
            variant="outlined"
            id={props.name}
            {...field}
            {...fieldProps}
            helperText={(meta.touched && meta.error) || fieldProps.helperText}
        />
    );
}