import React from "react";
import MUIDataTable from "mui-datatables";
import {Button, Grid, Tooltip, IconButton} from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import {muiDataTableLabels} from 'utils/i18nUtils'
import Loader from "components/Loader/Loader";
import CreateEditShare from "components/CreateEditShare";
import { withStyles } from "@material-ui/styles";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { centralizeColumn } from "utils/materialUtils";


class SharesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareDialogOpen: false,
      showDeleteConfirmationModal: false,
      columns: [
        {name: 'username', label: 'אימייל'},
        {name: 'project', label: 'פרויקט', options: {customBodyRenderLite: this.renderProject}},
        {
          name: 'actions',
          label: 'פעולות',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: this.renderActionsColumn,
            ...centralizeColumn,
          },
        },
      ]
    }
  }

  componentDidUpdate = (oldProps) => {
    if (!oldProps.justSubmitted && this.props.justSubmitted) {
      this.setState({justSubmitted: true, showDeleteConfirmationModal: false})
    }
  }
  componentDidMount = () => {
    this.props.onMount();
  }

  handleEditIcon = (share) => {
    this.setState({selectedShare: share, shareDialogOpen: true, justSubmitted: false});
  }
  
  handleShareDialogClose = () => {
    this.setState({shareDialogOpen: false, justSubmitted: false});
  }

  closeConfirmationDialog = () => {
    this.setState({confirmation: {open: false}});
  }

  handleShareDeleted = (project) => {
    this.closeConfirmationDialog();
    this.props.onShareDeleted(project)
  }

  renderToolbar = () => {
    return (
      <>
        <Button variant="contained" color="primary" onClick={() => this.handleEditIcon(undefined)}>שיתוף חדש</Button>
      </>
    );
  }

  renderProject = (dataIndex) => {
    const {shares, projects} = this.props;
    const share = shares[dataIndex]
    if (!share) {return;}
    
    return share.projectName;
  }

  renderActionsColumn = (dataIndex) => {
    const {shares, classes} = this.props;
    const share = shares[dataIndex]
    if (!share) {return;}
    
    return (
      <>
        <Tooltip arrow title="מחיקת שיתוף">
          <IconButton
            aria-label="מחיקת שיתוף"
            className={classes.actionIcon}
            onClick={() => this.setState({showDeleteConfirmationModal: true, selectedShare: share})}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderConfirmDialog = () => {
    const {showDeleteConfirmationModal, selectedShare = {}} = this.state;
    
    return (
      <ConfirmDialog
        variant="error"
        open={showDeleteConfirmationModal}
        title={`מחיקת שיתוף`}
        subtitle={`האם למחוק את השיתוף של '${selectedShare.projectName}' עבור ${selectedShare.username}?`}
        onConfirm={() => this.props.onDeleteShare(selectedShare)}
        confirmText="מחיקה"
        loading={this.props.submitting}
        onCancel={() => this.setState({showDeleteConfirmationModal: false, selectedShare: null})}
      />
    );
  }
  render() {
    const {shares, loading} = this.props;
    const {shareDialogOpen, selectedShare, columns, justSubmitted} = this.state;
    return (
      <>
        <PageTitle title="השיתופים שלי" />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Loader loading={loading}>
              <MUIDataTable
                title="רשימת השיתופים"
                data={shares}
                columns={columns}
                options={{
                  filterType: "textField",
                  textLabels: muiDataTableLabels,
                  selectableRows: 'none',
                  pagination: false,
                  filter: false,
                  search: false,
                  searchOpen: true,
                  print: false,
                  download: false,
                  viewColumns: false,
                  selectableRowsOnClick: true,
                  responsive: 'standard',
                  customToolbar: this.renderToolbar,
                }}
              />
            </Loader>
          </Grid>
        </Grid>
        <CreateEditShare
          justSubmitted={justSubmitted}
          open={shareDialogOpen}
          share={selectedShare}
          onClose={this.handleShareDialogClose}
        />
        {this.renderConfirmDialog()}
      </>
    );
  }
}

const styles = theme => ({
  actionIcon: {
    padding: theme.spacing(1),
  },
  summaryTable: {
    marginTop: theme.spacing(4),
  },
})
export default withStyles(styles, { withTheme: true })(SharesPage);
