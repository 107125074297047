import * as Yup from 'yup';

// Yup.addMethod(Yup.string, 'domain', function(message) {
//   const msg = message || 'Not a valid domain';
//   return this.test('domain', msg, function (value) {
//     const validHostRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
//     return !value || validHostRegex.test(value);
//   })
// })

export default Yup;
