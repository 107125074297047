import {eventChannel, buffers} from 'redux-saga';
import {put, call, takeLatest, fork, take, cancel, delay, takeEvery} from 'redux-saga/effects';
import {ActionTypes as AuthActionTypes, Actions as AuthActions} from 'modules/auth/actions';
import axios from 'services/axios';
import config from 'config';
import {ActionTypes as PagesActions} from 'pages/actions';
import { retrieveAccessToken, parseAccessToken, storeAccessToken, clearAccessToken } from 'utils/authUtils';
import _ from 'lodash';

export function* heartbit() {
    try {
        yield delay(config.heartbitInitialDelay);
        yield call(axios.get, 'ping');
        while (true) {
            yield delay(config.heartbitPollingTime);
            yield call(axios.get, 'ping');
            yield call(axios.get, window.location.origin);
        }
    } catch (err) {
        console.error(err);
    }
}

export function* logout() {
    yield call(clearAccessToken);
    yield put(AuthActions.LOGOUT_SUCCESS());
};

function* updateOnMouseOrKeyboardTouch() {
    const channel = eventChannel(emit => {
        document.addEventListener('click', emit);
        document.addEventListener('keydown', emit);
        return () => {
            document.removeEventListener('click', emit);
            document.removeEventListener('keydown', emit);
        }
    }, buffers.dropping(1));
    try {
        while(true) {
            yield delay(config.idleThrottle * 1000);
            yield take(channel);
            yield put(AuthActions.ACTIVITY_DETECTED());
        }
    } finally {
        channel.close();
    }
}

export function* monitorActivity() {
    const task = yield fork(updateOnMouseOrKeyboardTouch);
    yield take(AuthActionTypes.LOGOUT_SUCCESS);
    yield cancel(task);
}


function* loginWithPassword(action) {
    try {
        yield put(AuthActions.LOGIN_REQUEST());
        const {username, password} = action.payload;
        const {data} = yield call(axios.post, `login`, {username, password});
        const {access_token} = data;
        if (!access_token) {
            throw new Error('No access token');
        }
        yield call(storeAccessToken, access_token)
        const user = parseAccessToken(access_token)
        yield put(AuthActions.LOGIN_SUCCESS(user));
    } catch (err) {
        console.error(err)
        yield put(AuthActions.LOGIN_FAILURE(err));
    }
}

function* consolidateAuth() {
    const accessToken = yield call(retrieveAccessToken);
    const user = parseAccessToken(accessToken)
    if (!user || (user.exp * 1000) < Date.now()) {
        yield call(logout);
    } else {
        yield put(AuthActions.LOGIN_SUCCESS(user));
    }
}

function* logoutOn401() {
    while (true) {
        const skippedActions = [AuthActionTypes.LOGIN_FAILURE]
        const action = yield take('*')
        if (skippedActions.includes(action.type)) {
            continue;
        }
        if (action.error && _.get(action.payload, 'response.status') === 401) {
            yield fork(logout)
            yield delay(config.logoutThrottle)
        }
    }
}
const sagags = [
    takeLatest([AuthActionTypes.LOGOUT_SUCCESS, AuthActionTypes.LOGIN_SUCCESS], heartbit),
    takeLatest(AuthActionTypes.LOGIN_SUCCESS, monitorActivity),
    takeLatest(PagesActions.LOGIN_FORM_SUBMITTED, loginWithPassword),
    takeLatest(PagesActions.HEADER_LOGOUT_REQUESTED, logout),
    takeLatest('STORE_LOADED', consolidateAuth),
    takeEvery('STORE_LOADED', logoutOn401),
]
export default sagags;
