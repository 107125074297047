import { createSelector } from 'reselect';
import * as ProjectSelectors from 'modules/projects/selectors';
 
export const sliceSelector = (state) => state.shares;
export const shares = createSelector(sliceSelector, slice => slice.shares || []);
export const isLoading = createSelector(sliceSelector, slice => !!slice.loading);
export const isSubmitting = createSelector(sliceSelector, slice => !!slice.submitting);
export const justSubmitted = createSelector(sliceSelector, slice => !!slice.justSubmitted);
export const submitError = createSelector(sliceSelector, slice => slice.submitError);
export const enhancedShares = createSelector(shares, ProjectSelectors.projects, (shares, projects) => {
    return shares.map(s => {
        const project = projects.find(p => p.id === s.projectId);
        return {...s, projectName: project && project.name}
    })
});