import { mergeItemsBy } from 'utils/reduxUtils';
import {ActionTypes} from './actions';
import _ from 'lodash';

const initialValue = {
}

const formatError = (payload) => {
    const errorCode = _.get(payload, 'response.data.key');
    switch (errorCode) {
      case 'shareExists':
        return 'שיתוף עבור משתמש ופרויקט זה כבר קיים במערכת';
      default:
        return 'אופס. נראה שהבקשה נכשלה';
    }
  }

const sharesReducer = (state = initialValue, action) => {
    let nextState;
    const {type, payload} = action;
    switch (type) {
    case ActionTypes.LOAD_SHARES_REQUEST:
        nextState = {...state, shares: [], loading: true};
        break;
    case ActionTypes.LOAD_SHARES_SUCCESS:
        nextState = {...state, shares: payload.items, loading: false};
        break;
    case ActionTypes.LOAD_SHARES_FAILURE:
        nextState = {...state, loading: false};
        break;
    case ActionTypes.SHARE_FORM_SUBMIT_REQUEST:
    case ActionTypes.DELETE_SHARE_SUBMIT_REQUEST:
        nextState = {...state, submitting: true, justSubmitted: false, submitError: null};
        break;
    case ActionTypes.SHARE_FORM_SUBMIT_SUCCESS:
        nextState = {...state, submitting: false, justSubmitted: true, shares: mergeItemsBy(state.shares, payload)};
        break;
    case ActionTypes.DELETE_SHARE_SUBMIT_SUCCESS:
        nextState = {...state, submitting: false, justSubmitted: true, shares: state.shares.filter(p => !payload.ids.includes(p.id))};
        break;
    case ActionTypes.SHARE_FORM_SUBMIT_FAILURE:
    case ActionTypes.DELETE_SHARE_SUBMIT_FAILURE:
        nextState = {...state, submitting: false, submitError: formatError(payload)};
        break;
    default:
        nextState = state;
    }
    return nextState;
};

export default sharesReducer;
