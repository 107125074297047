import React from "react";
import {
  Grid, TableCell, Breadcrumbs, Tooltip, IconButton, Link, Typography,
} from '@material-ui/core';

import MUIDataTable from "mui-datatables";
import { Link as RouterLink } from "react-router-dom";


import {
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  Domain as DomainIcon,
  Edit as EditIcon,
  AddCircleOutlineOutlined as AddIcon,
  Link as LinkIcon,
} from '@material-ui/icons';

// components
import PageTitle from "../../components/PageTitle";
import {muiDataTableLabels} from 'utils/i18nUtils'
import Loader from "components/Loader/Loader";
import { withStyles } from "@material-ui/styles";
import CreateEditPost from "components/CreateEditPost";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import { centralizeColumn } from "utils/materialUtils";
import { formatDateTime } from "utils/formatters";
import { safeUrl } from "utils/generalUtils";


class ProjectsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postDialogOpen: false,
      confirmation: {open: false},
      columns: [
        {name: 'title', label: 'כותרת המאמר'},
        {name: 'slug', label: 'סלאג'},
        {name: 'status', label: 'סטטוס'},
        {name: 'updatedAt', label: 'עדכון אחרון', options: {customBodyRenderLite: this.renderUpdateDate}},
        {name: 'wordCount', label: 'מילים במאמר', options: {customBodyRenderLite: this.renderWordCount}},
        {name: 'externalUrl', label: 'קישור למאמר', options: {customBodyRenderLite: this.renderPostExternalUrl}},
        {
          name: 'actions',
          label: 'פעולות',
          options: {
            filter: false,
            sort: true,
            customBodyRenderLite: this.renderActionsColumn,
            ...centralizeColumn,
          }
        },
      ]
    }
  }
  componentDidMount = () => {
    this.props.onMount();
  }

  componentDidUpdate = (oldProps) => {
    if (!oldProps.justSubmitted && this.props.justSubmitted) {
      this.setState({justSubmitted: true})
    }
  }

  handleShowArchivedChange = (e) => {
    const isHidden = e.target.checked;
    this.props.onShowHiddenChange(isHidden);
  }

  handleEditIcon = (project) => {
    this.setState({selectedPost: project, postDialogOpen: true, justSubmitted: false});
  }
  
  handleProjectDialogClose = () => {
    this.setState({postDialogOpen: false, justSubmitted: false});
  }

  closeConfirmationDialog = () => {
    this.setState({confirmation: {open: false}});
  }

  handlePostChangeState = (post) => {
    this.closeConfirmationDialog();
    this.props.onPublishPost(post)
  }

  handleCellClick = (_, {colIndex, dataIndex}) => {
    const nonClickableColumns = [this.state.columns.length - 1, this.state.columns.length - 2]
    if (nonClickableColumns.includes(colIndex)) {
      return;
    }
    const {posts} = this.props.project;
    const post = posts[dataIndex]
    if (!post) {
      return null;
    }
    this.handleEditIcon(post)
  }

  confirmPublishPost = (post) => {
    const activateTexts = {
      title: `פרסום מאמר`,
      subtitle: `האם לפרסם את המאר "${post.title}"?`,
      confirmText: 'פרסום',
    }

    const confirmation = {
      open: true,
      ...activateTexts,
      onConfirm: () => this.handlePostChangeState(post),
      onCancel: this.closeConfirmationDialog
    }
    this.setState({confirmation})
  }

  renderToolbar = () => {
    return (
      <>
        <Tooltip arrow title="הוספת מאמר חדש">
          <IconButton color="primary" aria-label="הוספת מאמר חדש" onClick={() => this.handleEditIcon()}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderWordCount = (dataIndex) => {
    const {posts} = this.props.project;
    const post = posts[dataIndex]
    if (!post || !post.description) {
      return null;
    }
    const textOnly = post.description.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, ' ')
    const wordCount = textOnly.split(/[\s\n//]/).filter(a => a).length
    return wordCount;
  }

  renderPostExternalUrl = (dataIndex) => {
    const {posts} = this.props.project;
    const post = posts[dataIndex]
    if (!post || !post.externalUrl) {
      return null;
    }
    const text = 'קישור למאמר';
    return (
      <Tooltip arrow title={text}>
        <IconButton color="primary" aria-label={text} component="a" href={safeUrl(post.externalUrl)} target="_blank" rel="noreferrer">
          <LinkIcon fontSize="inherit" />
        </IconButton>
    </Tooltip>
    )
  }

  renderUpdateDate = (dataIndex) => {
    const {posts} = this.props.project;
    const post = posts[dataIndex]
    if (!post) {
      return null
    }
    return formatDateTime(post.updatedAt)
  }
  renderActionsColumn = (dataIndex) => {
    const {classes} = this.props;
    const {posts} = this.props.project;
    const post = posts[dataIndex]
    if (!post) {
      return null;
    }
    const Icon = post.active ? ArchiveIcon : UnarchiveIcon;
    const text = 'פרסום מאמר';
    return (
      <>
        <Tooltip arrow title={text}>
          <IconButton aria-label={text} className={classes.actionIcon} onClick={() => this.confirmPublishPost(post)}>
            <Icon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  renderConfirmDialog = () => {
    return (
      <ConfirmDialog
          {...this.state.confirmation}
      />
    );
  }

  renderBreadcrumbs = (project) => {
    return (
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} color="inherit" to="/app/projects">
          כל הפרויקטים
        </Link>
        <Typography color="textPrimary">{project.name}</Typography>
      </Breadcrumbs>
    );
  }

  renderCellByKey = (summary, key, mapper, ...args) => {
    const {classes} = this.props;
    return summary.map(({[key]: raw}) => {
      const value = mapper ? mapper(raw, ...args) : raw;
      return (
        <TableCell key={value} className={classes.summaryCell}>{value}</TableCell>
      )
    });
  }

  render() {
    const {project, loading} = this.props;
    const {postDialogOpen, selectedPost, columns, justSubmitted} = this.state;

    if (loading) {
      return (
        <Loader loading />
      )
    }
    if (!project || !project.posts) {
      return 'נראה שיש בעיה. יש לפנות למנהל המערכת';
    }
    const {name} = project;
    return (
      <>
        {this.renderBreadcrumbs(project)}
        <PageTitle title={name} icon={DomainIcon} />
        <Grid container spacing={4}>
          <Grid item xs={12} lg={9}>
            <MUIDataTable
                title={"רשימת המאמרים"}
                data={project.posts}
                columns={columns}
                options={{
                  sortOrder: {name: 'name', direction: 'asc'},
                  filterType: "textField",
                  onCellClick: this.handleCellClick,
                  textLabels: muiDataTableLabels,
                  selectableRows: 'none',
                  pagination: false,
                  filter: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  search: false,
                  searchOpen: true,
                  selectableRowsOnClick: true,
                  responsive: 'standard',
                  customToolbar: this.renderToolbar,
                  tableBodyMaxHeight: '700px'
                }}
              />
          </Grid>
        </Grid>
        <CreateEditPost
          justSubmitted={justSubmitted}
          open={postDialogOpen}
          post={selectedPost}
          onClose={this.handleProjectDialogClose}
        />
        {this.renderConfirmDialog()}
      </>
    );
  }
}

const styles = theme => ({
  actionIcon: {
    padding: theme.spacing(1),
  },
  summaryCell: {
    padding: 15,
  }
})

export default withStyles(styles, { withTheme: true })(ProjectsPage);
