import React, {useRef, useState} from 'react';
import {Field, useFormikContext } from 'formik';
import _ from 'lodash';
import { Editor } from '@tinymce/tinymce-react';
import config from 'config';
import { FormControl, FormHelperText } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  outlinedInput: {
      padding: 2,
      paddingTop: 4,
      display: 'block',
  },
  '@global': {
    '.tox.tox-tinymce': {
      border: 'none'
    }
  }
}));


export default function AutoComplete({fieldProps = {}, name, label, options = [], ...props}) {
  const context = useFormikContext();
  const error = (context.touched[name] && context.errors[name]);
  const editorRef = useRef(null);
  const [isFocused, setFocus] = useState(false)
  const classes = useStyles();
  return (
    <Field
      name={name}
      id={name}
      fullWidth
      component={Editor}
      {...props}
    >
      {({ field, form, meta }) => (
        <FormControl fullWidth color="primary" focused={isFocused} error={!!error} margin="normal">
          <InputLabel variant="outlined" htmlFor="select-multiple-accounts" shrink focused={isFocused}>
              {label}
          </InputLabel>
          <OutlinedInput
            color="primary"
            inputComponent={Editor}
            notched
            fullWidth
            label={label}
            className={classes.outlinedInput}
            inputProps={{
              onInit: (evt, editor) => editorRef.current = editor,
              apiKey: config.tinyEditorApiKey,
              ..._.omit(field, ['onChange']),
              onEditorChange: (value, editor) => {
                field.onChange({ target: { name, value} });
              },
              onFocus: () => setFocus(true),
              onBlur: () => setFocus(false),
              init: {
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect  | bold italic underline strikethrough' +
                  '| alignleft aligncenter alignright alignjustify ' + 
                  '| outdent indent |  numlist bullist '+
                  '| forecolor backcolor | link' +
                  '| removeformat | fullscreen',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                branding: false,
                directionality: 'rtl',
                language: 'he_IL'
              },
            }
          } />
          {error && <FormHelperText id="my-helper-text">{error}</FormHelperText>}
      </FormControl>
      )}
    </Field>
  );
}

//