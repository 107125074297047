import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, CircularProgress, DialogTitle,
} from '@material-ui/core';

import useStyles from './styles';
import classnames from 'classnames';

export default function ConfirmDialog({open, variant = 'primary', onClose, loading, title, subtitle, customContent, onConfirm, onCancel, confirmText, cancelText = 'ביטול'}) {
  const classes = useStyles();
  const isError = variant === 'error';
  const titleClasses = classnames(classes.dialogTitle, {[classes.bgError]: isError})
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="cofirm-dialog-title" disableBackdropClick disableEscapeKeyDown={loading}>
      <DialogTitle id="cofirm-dialog-title" className={titleClasses}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {subtitle && <DialogContentText id="cofirm-dialog-description">
          {subtitle}
        </DialogContentText>}
        {customContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} autoFocus>
          {cancelText}
        </Button>
        <div className={classes.wrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classnames({[classes.btnError]: isError})}
            autoFocus
            disabled={loading}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
}