import { mergeItemsBy } from 'utils/reduxUtils';
import {ActionTypes} from './actions';
import {ActionTypes as PagesActionTypes} from 'pages/actions';

const initialValue = {
  activitiesFilter: {showArchived: false},
}

const projectsReducer = (state = initialValue, action) => {
  let nextState;
  const {type, payload} = action;
  switch (type) {

  default:
    nextState = state;
  }
  return nextState;
};

export default projectsReducer;
