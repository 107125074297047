import {connect} from 'utils/reduxUtils';
import Component from './SupplierProjectsModal';
import * as SuppliersSelectors from 'modules/suppliers/selectors';
import {Actions} from 'pages/actions';

const mapDispatchToProps = {
    onSupplierChange: Actions.SUPPLIER_PROJECTS_MODAL_CHANGE,
};

const mapStateToProps = state => {
    return {
        loading: SuppliersSelectors.isSubmitting(state),
        submitError: SuppliersSelectors.submitError(state),
        projects: SuppliersSelectors.supplierProjects(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
